import React, { useEffect } from "react"
import Header from "../components/Header"

const Error: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green">
			<Header addClass="text-white" />
			<div className="pt-32 ">
				<div className="text-center relative z-0 -mt-[1px] max-w-[750px] ml-auto mr-auto">
					<h2 className="h3 pb-7  px-4 text-white">Hier ist etwas schief gelaufen...</h2>
				</div>
			</div>
			<div className="text-center pt-5 py-10">
				<a href="/" className="inline-block btn btn-invert mt-8">
					Zurück zur Aktion
				</a>
			</div>
		</div>
	)
}
export default Error
