import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import { PACKAGES } from "../config"
import Header from "../components/Header"

const FAQ: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green">
			<Header addClass="text-white" />
			<div className="container max-w-[800px] mx-auto px-4 ">
				<h2 className="h2 pt-12 text-white text-center ">FAQ</h2>
				<h3 className="h3 mt-16 mb-6 sm:mb-16 text-center ">
					<div className="text-white font-Atma font-bold">1. Allgemeine Informationen </div>
				</h3>

				<AccordionItem headline="Worum geht es bei der aktuellen Aktion von PURmacherei HaferHAPS?" num="1.">
					<p className="text-black">
						Im Rahmen unserer HaferHAPS Probier-Rabatt-Aktion kann ein HaferHAPS Aktionsprodukt bei einem unserer Handelspartner probiert werden. Für die Teilnahme ist der Kauf
						einer HaferHAPS Aktionspackung im Handel erforderlich. Ausgenommen sind Einkäufe im Online-Shop. Nach erfolgreicher Online-Teilnahme wird dir 1 Euro vom gezahlten
						Kaufpreis des Aktionsproduktes auf das von dir benannte Bankkonto zurücküberwiesen.
					</p>
				</AccordionItem>

				<AccordionItem headline="Wer darf an der Aktion teilnehmen?	" num="2.">
					<p className="text-black">
						Teilnahmeberechtigt sind natürliche Personen ab 18 Jahren mit einem Konto im SEPA Raum (ein Land innerhalb der Europäischen Union sowie Island, Lichtenstein, Norwegen,
						Schweiz, Andorra, Monaco, San Marino, Vatikanstadt, Vereinigtes Königreich, Gibraltar, Isle of Man, Guernsey und Jersey).
					</p>
				</AccordionItem>

				<AccordionItem headline="Welche Produkte nehmen an der Aktion teil?" num="3.">
					<p className="text-black">An der Aktion nehmen folgende Produkte teil: HaferHAPS Himbeere 50g, HaferHAPS Kokos 50g, HaferHAPS Kakao 50g und HaferHAPS Mandel 50g.</p>
				</AccordionItem>

				<AccordionItem headline="Wo finde ich die Aktionspackungen?" num="4.">
					<p className="text-black">
						Die Aktionspackungen sind in Deutschland bei den teilnehmenden Händlern verfügbar. Wir können leider nicht sagen, ob und wann die Packungen in einem konkreten
						Einkaufsmarkt erhältlich sind. Wenn du bisher keine Aktionspackungen finden konntest, probiere es am besten in einem anderen Markt – oder schau einfach beim nächsten
						Einkauf nochmal beim Süßwaren-Regal vorbei. Eine Liste unserer Verkaufsstellen findest Du zudem auf{" "}
						<a href="https://www.purmacherei.de/pages/verkaufsstellen" className="underline" target="_blank" rel="nofollow">
							www.purmacherei.de/pages/&#8203;verkaufsstellen
						</a>
						.
					</p>
				</AccordionItem>

				<h3 className="h3 mt-16 mb-6 sm:mb-16 text-center ">
					<div className="text-white font-Atma font-bold">2. Durchführung und Abwicklung</div>
				</h3>
				<AccordionItem headline="Wie kann ich an der Aktion teilnehmen?" num="5.">
					<p className="text-black">
						1. Eine Aktionspackung HaferHAPS im Handel kaufen (Onlineshops sind von der Aktion ausgenommen).
						<br />
						<br />
						2. Foto vom Kassenbon auf der Aktionsseite auf{" "}
						<a className="underline" href="https://www.vegansnacken-purmacherei.de">
							www.vegansnacken-purmacherei.de
						</a>{" "}
						hochladen. <br />
						<br />
						3. Das Kontaktformular, Produktangaben und Bankinformationen ausfüllen. Die Teilnahmebedingungen und das Mindestalter akzeptieren und auf „Jetzt teilnehmen“ klicken.{" "}
						<br />
						<br />
						4. Nach dem Absenden der Teilnahmedaten erhältst du per E-Mail eine Eingangsbestätigung. Die PURmacherei prüft im Anschluss die Einhaltung der Teilnahmevoraussetzungen.
						Falls diese nicht vorliegen, erhältst du eine Benachrichtigung. Bei Vorliegen der Teilnahmebedingungen wird der Rabatt für das HaferHAPS Produkt binnen vier Wochen auf
						das von dir angegebene Konto erstattet.
					</p>
				</AccordionItem>
				<AccordionItem headline="In welchem Zeitraum kann an der Aktion teilgenommen werden?" num="6.">
					<p className="text-black">
						Die HaferHAPS Probier-Rabatt-Aktion findet bis zum 30.09.2024 statt. Fotos vom Kassenbon können bis zum 30.09.2024 um 23:59 Uhr ausschließlich online unter{" "}
						<a className="underline" href="https://www.vegansnacken-purmacherei.de">
							www.vegansnacken-purmacherei.de
						</a>{" "}
						hochgeladen werden.
					</p>
				</AccordionItem>
				<AccordionItem headline="Wie lade ich die Fotos vom Kassenbon und der Aktions&shy;packung richtig hoch?" num="7.">
					<p className="text-black">
						Es können beim Upload nur Fotos im Format JPG, PNG und PDF und einer maximalen Größe von 8 MB pro Foto hochgeladen werden. Auf dem Kassenbon müssen die folgenden Daten
						erkennbar sein: Kaufdatum, Kaufpreis des Aktionsproduktes, Bonsumme, Einkaufsstätte sowie das Aktionsprodukt. Auf dem Kassenbon abgebildete personenbezogene Daten (z.
						B. Namen von Beschäftigten) sind nicht abzulichten oder vorab zu schwärzen oder in sonstiger Weise unkenntlich zu machen. Unvollständige sowie verspätete Angaben können
						leider nicht berücksichtigt werden.
					</p>
				</AccordionItem>

				<AccordionItem headline="Wo finde ich meine IBAN/BIC?" num="8.">
					<p className="text-black">
						Deine persönliche IBAN-Nummer und den BIC findest du auf der Vorder- und/oder Rückseite deiner Bankkarte sowie im Online-Banking. Die Zahlenfolge der IBAN für
						Deutschland beginnt stets mit DE und enthält 22 Zeichen. Die Zahlenfolge der IBAN für Österreich beginnt stets mit AT und enthält 20 Zeichen.
					</p>
				</AccordionItem>

				<AccordionItem headline="Ich habe versehentlich einen Fehler bei der Dateneingabe gemacht, was kann ich tun?" num="9.">
					<p className="text-black">
						Bitte wende dich umgehend an den PURmacherei Aktions-Kundenservice per E-Mail an{" "}
						<a className="underline" href="mailto:info@purmacherei-probieren.de">
							info@purmacherei-probieren.de
						</a>
						. Wenn die Rücküberweisung noch nicht getätigt wurde, können wir deine Daten korrigieren.
					</p>
				</AccordionItem>

				<AccordionItem headline="Warum wird mein Kassenbon nicht erkannt? " num="10.">
					<p className="text-black">
						Auf dem Kassenbon müssen die folgenden Daten erkennbar sein: Kaufdatum, Kaufpreis des Aktionsproduktes, Bonsumme, Einkaufsstätte sowie das Aktionsprodukt.
					</p>
				</AccordionItem>

				<AccordionItem headline="Werde ich informiert, wenn die Teilnahmebedingungen nicht erfüllt sind?" num="11.">
					<p className="text-black">
						Wir informieren dich, wenn die Teilnahmebedingungen nicht erfüllt sind. Du erhältst in diesem Fall von uns eine Benachrichtigung an deine angegebene E-Mail-Adresse.
					</p>
				</AccordionItem>

				<AccordionItem headline="Ist eine Mehrfach-Teilnahme möglich?" num="12.">
					<p className="text-black">
						Pro Bankkonto kann während des Aktionszeitraums eine Packung HaferHAPS probiert werden. Jeder Kassenbon darf nur einmalig zur Teilnahme an der Aktion genutzt werden.
					</p>
				</AccordionItem>

				<AccordionItem headline="Ich habe den Kassenbon nicht mehr, was kann ich tun bzw. kann ich trotzdem teilnehmen?" num="13.">
					<p className="text-black">Eine Teilnahme ohne ein Foto des Kassenbons ist leider nicht möglich.</p>
				</AccordionItem>

				<h3 className="h3 mt-16 mb-6 sm:mb-16 text-center ">
					<div className="text-white font-Atma font-bold">3. Nach der Teilnahme</div>
				</h3>

				<AccordionItem headline="Wie kann ich sicher sein, dass meine Teilnahme an der Aktion erfolgreich war? " num="14.">
					<p className="text-black">
						Nach dem Absenden der Teilnahmedaten erhältst du per E-Mail eine Eingangsbestätigung. Die PURmacherei prüft im Anschluss die Einhaltung der Teilnahmevoraussetzungen.
						Falls diese nicht vorliegen, erhältst du eine Benachrichtigung. Eine Bestätigung der Überweisung wird nicht versendet.
					</p>
				</AccordionItem>

				<AccordionItem headline="Wie lange dauert es, bis ich mein Geld zurückerhalte?" num="15.">
					<p className="text-black">
						Sofern du alle Teilnahmevoraussetzungen erfüllt hast, bekommst du den Rabatt innerhalb eines Zeitraums von vier Wochen auf das von dir angegebene Konto überwiesen.
					</p>
				</AccordionItem>

				<AccordionItem headline="Ist der Status der Rückerstattung einsehbar?	" num="16.">
					<p className="text-black">
						Leider ist der Status der Rückerstattung des Rabatts nicht online abrufbar. Um Auskunft über den Status der Rückerstattung zu erhalten, kannst du dich unter Angabe
						deines Namens per E-Mail an{" "}
						<a className="underline" href="mailto:info@purmacherei-probieren.de">
							info@purmacherei-probieren.de
						</a>{" "}
						an das Service-Team wenden.
					</p>
				</AccordionItem>

				<h3 className="h3 mt-16 mb-6 sm:mb-16 text-center ">
					<div className="text-white font-Atma font-bold">4. Sonstiges</div>
				</h3>
				<AccordionItem headline="Was passiert mit meinen Daten?" num="17.">
					<p className="text-black mb-6">
						PURmacherei nutzt deine Daten im Rahmen der „HaferHAPSProbier-Rabatt-Aktion“ ausschließlich zum Zwecke der Durchführung und Abwicklung der Aktion. Diese sind im
						Anmeldeformular als Pflichtfelder gekennzeichnet. Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 b) DSGVO. Falls du diese Daten nicht
						bereitstellst, kannst du an der Aktion nicht teilnehmen. Die darüber hinaus erhobenen Daten sind freiwillig und erfolgen auf der rechtlichen Grundlage unseres
						berechtigten Interesses (Art. 6 Abs. 1 f) DSGVO). Die Anrede fragen wir ab, um dich wunschgemäß ansprechen zu können. Zur Durchführung der Aktion setzen wir als
						Dienstleister die direct services Gütersloh GmbH (Reinhard-Mohn-Straße 300, 33333 Gütersloh Germany) ein, mit der ein Auftragsverarbeitungsverhältnis besteht. Zur
						Auszahlung der Cashback-Beträge wird ein in Deutschland ansässiges Zahlungsinstitut eingesetzt.
					</p>
					<p className="text-black mb-6">
						Die o.g. Daten werden gelöscht, sobald diese für die Erreichung der vorgenannten Zwecke nicht mehr erforderlich sind. Im Falle der Verarbeitung Ihrer IP-Adresse erfolgt
						die Löschung spätestens nach 3 Monaten, bei Missbrauchsfällen nach Abschluss des behördlichen oder gerichtlichen Verfahrens. Weitere Informationen zum Datenschutz
						findest du unter:{" "}
						<a className="underline" target="_blank" rel="nofollow" href="https://www.purmacherei.de/policies/privacy-policy">
							https://www.purmacherei.de/policies/&#8203;privacy-policy
						</a>
					</p>
				</AccordionItem>

				<div className="text-white pt-8 text-center">
					<p className="mb-6 ">Du hast keine Antwort auf deine Frage erhalten?</p>
					<p className="">
						Das Service-Team ist per E-Mail unter{" "}
						<a className="underline" href="mailto:info@purmacherei-probieren.de">
							info@purmacherei-probieren.de
						</a>{" "}
						erreichbar.
					</p>
				</div>
			</div>
			<div className="text-center pt-5 py-10">
				<button onClick={() => navigate(-1)} className="mt-8 btn-invert btn">
					Zurück
				</button>
			</div>
		</div>
	)
}
export default FAQ
