import { Link } from "react-router-dom"

const Sogehts = () => {
	return (
		<>
			<div className="bg-green w-full px-4">
				<div className="text-center js-sogehts pt-14">
					<h2 className="h2 text-white">SO EINFACH GEHT’S:</h2>
				</div>
				<div className="grid gap-4 md:gap-4 grid-cols-3 text-center max-w-[1000px] mx-auto pt-6 sm:pt-16 ">
					<div>
						<div className="min-h-[120px] sm:min-h-[220px]">
							<img src={process.env.PUBLIC_URL + "/images/step1.svg"} className="w-full h-auto max-w-[80%] sm:max-w-[160px] mx-auto" alt="" />
						</div>
						<div className="sogehts-text">
							<div className="sogehts-text-inner">
								Produkt im
								<br />
								Handel kaufen*
							</div>
						</div>
					</div>
					<div>
						<div className="min-h-[120px] sm:min-h-[220px]">
							<img src={process.env.PUBLIC_URL + "/images/step2.svg"} className="w-full h-auto max-w-[80%] sm:max-w-[156px] mx-auto" alt="" />
						</div>
						<div className="sogehts-text">
							<div className="sogehts-text-inner">Foto vom Kassenbon hochladen</div>
						</div>
					</div>
					<div>
						<div className="min-h-[120px] sm:min-h-[220px]">
							<img src={process.env.PUBLIC_URL + "/images/step3.svg"} className="w-full h-auto max-w-[80%] sm:max-w-[173px]  mx-auto" alt="" />
						</div>
						<div className="sogehts-text">
							<div className="sogehts-text-inner">
								1 Euro Rabatt <br />
								erhalten
							</div>
						</div>
					</div>
				</div>

				<div className=" text-center text-white font-Quicksand text-[13px] sm:text-[14px] leading-4 font-bold max-w-[722px] mx-auto pt-8 sm:pt-28 pb-12">
					*Teilnahme nur online bis zum 30.09.2024 ab 18 Jahren mit einem Konto im SEPA-Raum möglich. <br />
					Pro Bankkonto kann ein HaferHAPS Aktionsprodukt probiert werden. <br />
					Ausgenommen sind Einkäufe im Online-Shop. <br />
					Die Erstattungen sind auf insgesamt 5.000 Stück begrenzt.
					<br />
					Weitere Informationen in den{" "}
					<Link to="/teilnahmebedingungen" className="underline font-Quicksand font-bold ">
						Teilnahmebedingungen
					</Link>
					.
				</div>
			</div>
			<div className="h-0 max-w-[1452px] mx-auto text-right relative z-20">
				<img src={process.env.PUBLIC_URL + "/images/keks3.png"} className=" w-full max-w-[107px] sm:max-w-[313px] sm:mr-auto  -translate-y-[40%] sm:-translate-y-[60%]" alt="" />
			</div>
		</>
	)
}

export default Sogehts
