import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"

interface BankAccountProps {
	formElementChanged: Function
	validation: Object
}
const BankAccount: React.FC<BankAccountProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<div className="h-0 max-w-[1452px] mx-auto text-right relative z-20">
				<img src={process.env.PUBLIC_URL + "/images/mandeln.png"} className=" w-[80px] lg:w-[207px] ml-auto lg:ml-0 lg:mr-auto -translate-y-[20%]" alt="" />
			</div>

			<div className="container">
				<h2 className="h3 text-center  pb-11 text-yellow">Bankverbindung</h2>

				<div className="grid gap-5 grid-cols-1 md:grid-cols-[1fr_1fr] items-start mb-6 max-w-[600px] mx-auto">
					<ElemInput
						label={"Kontoinhaber:*"}
						props={{
							type: "text",
							name: "bankName",
							value: globalState.bankName,
							required: "required",
							minLength: 1,
							onChange: e => formElementChanged(e, "bankName"),
						}}
						hasError={validation["invalid_bankName"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_bankName_errMsg"] || "Bitte Kontoinhaber eingeben."}
					/>
					<ElemInput
						label={"IBAN:*"}
						props={{
							type: "text",
							name: "iban",
							value: globalState.iban,
							required: "required",
							minLength: 16,
							maxLength: 31,
							onChange: e => formElementChanged({ target: { value: e.target.value.replace(" ", "").toUpperCase() } }, "iban"), // remove whitespaces
						}}
						hasError={validation["invalid_iban"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_iban_errMsg"] || "Bitte IBAN eingeben."}
					/>
					{globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE" && (
						<ElemInput
							label={"BIC:*"}
							props={{
								type: "text",
								name: "bic",
								value: globalState.bic,
								required: "required",
								maxLength: 11,
								onChange: e => formElementChanged({ target: { value: e.target.value.replace(" ", "").toUpperCase() } }, "bic"),
							}}
							hasError={validation["invalid_bic"] ? true : false}
							className="col-span-2 md:col-span-1"
							errMsg={validation["invalid_bic_errMsg"] || "Bitte BIC eingeben."}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default BankAccount
