function Footer() {
	type TFooterLink = {
		text: string
		link: string
	}

	const footerLinks: TFooterLink[] = [
		{
			text: "Datenschutz",
			link: process.env.PUBLIC_URL + "/datenschutz",
		},
		{
			text: "Impressum",
			link: process.env.PUBLIC_URL + "/impressum",
		},
		// {
		// 	text: "AGB",
		// 	link: process.env.PUBLIC_URL + "/agb",
		// },
	]

	return (
		<footer className="bg-beige py-12">
			<div className="grid grid-cols-1 sm:grid-cols-3 text-[20px] font-bold text-brown font-Quicksand sm:ml-20 text-center sm:text-left ">
				<div>
					Follow us
					<ul className="pt-2">
						<li className="inline-block mr-4">
							<a href="https://www.tiktok.com/@purmacherei">
								<img src={process.env.PUBLIC_URL + "/images/footer/tictoc.svg"} className="w-[35px] " alt="" />
							</a>
						</li>
						<li className="inline-block mr-4">
							<a href="https://www.facebook.com/purmacherei/">
								<img src={process.env.PUBLIC_URL + "/images/footer/facebook.svg"} className="w-[35px] " alt="" />
							</a>
						</li>
						<li className="inline-block mr-4">
							<a href="https://www.instagram.com/purmacherei/">
								<img src={process.env.PUBLIC_URL + "/images/footer/insta.svg"} className="w-[35px] " alt="" />
							</a>
						</li>
						<li className="inline-block mr-4">
							<a href="https://www.linkedin.com/company/purmacherei/">
								<img src={process.env.PUBLIC_URL + "/images/footer/linkedin.svg"} className="w-[35px] " alt="" />
							</a>
						</li>
					</ul>
				</div>
				<div className="text-center md:text-right col-span-2">
					<nav>
						<ul>
							{footerLinks.map((footerLink, i) => (
								<li key={i} className="inline-block p-3">
									<a href={footerLink.link} className="text-[20px] font-bold text-brown font-Quicksand sm:ml-8 xl:ml-20">
										{footerLink.text}
									</a>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</footer>
	)
}

export default Footer
