import { useContext } from "react"
import { Context } from "../../store/store"
import ElemCheckbox from "../elements/Checkbox"
import { Link } from "react-router-dom"

interface LegalProps {
	formElementChanged: Function
	validation: Object
}
const Legal: React.FC<LegalProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<div className=" max-w-[595px] mx-auto">
			<ElemCheckbox
				label={
					<>
						Ich habe die{" "}
						<Link to="/teilnahmebedingungen" className="underline cursor-pointer">
							Teilnahmebedingungen
						</Link>{" "}
						gelesen und akzeptiere diese.*
					</>
				}
				props={{
					type: "checkbox",
					name: "terms",
					required: "required",
					value: globalState.terms,
					onClick: e => formElementChanged(e, "terms"),
				}}
				hasError={validation["invalid_terms"] ? true : false}
				className="sm:pb-10"
				errMsg={validation["invalid_terms_errMsg"] || "Bitte Teilnahmebedingungen bestätigen."}
			/>
			<ElemCheckbox
				label="Ich bin mindestens 18 Jahre alt.*"
				props={{
					type: "checkbox",
					name: "age",
					required: "required",
					value: globalState.age,
					onClick: e => formElementChanged(e, "age"),
				}}
				hasError={validation["invalid_age"] ? true : false}
				className="sm:pb-10"
				errMsg={validation["invalid_age_errMsg"] || "Bitte Alter bestätigen."}
			/>
			<ElemCheckbox
				label="Ja, ich möchte den Newsletter abonnieren. Ich stimme den Datenschutzrichtlinien zu und bin damit einverstanden Updates von der PURmacherei zu erhalten."
				props={{
					type: "checkbox",
					name: "newsletter",
					value: globalState.newsletter,
					onClick: e => formElementChanged(e, "newsletter"),
				}}
				hasError={validation["invalid_newsletter"] ? true : false}
				className="sm:pb-10"
				errMsg={validation["invalid_newsletter_errMsg"] || "Bitte Alter bestätigen."}
			/>

			<div className="text-yellow pl-8 text-[15px] leading-[20px]">
				Informationen zum Datenschutz findest du{" "}
				<Link to="/datenschutz" className="underline ">
					hier
				</Link>
				.
			</div>
		</div>
	)
}

export default Legal
