import { useEffect } from "react"
import Header from "../components/Header"

function Promoover() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-brown relative">
			<div className="absolute left-[60%] top-[0%] w-[40%] h-[70%] bg-[length:140%_140%] bg-left-bottom bg-[url('/src/bgimages/kreis.svg')] bg-no-repeat opacity-50"></div>
			<div className="absolute left-[0%] top-[20%] w-[30%] h-[70%] bg-[length:auto_100%] bg-right bg-[url('/src/bgimages/kreis.svg')] bg-no-repeat opacity-50"></div>

			<div className="text-center relative pt-20 xl:pt-0 ">
				<Header addClass="text-white" />
				<div className=" max-w-[1640px]  mx-auto">
					<h2 className="h2 text-white px-4 text-[36px] sm:text-[48px]">
						Die Probier-Rabatt-Aktion ist seit dem 01.10.2024 beendet. 
						<br />
						Vielen Dank an alle, die an unserer Aktion teilgenommen haben.
					</h2>

					<p className="text-white text-[25px]  sm…text-[31px] pt-14 font-Quicksand px-4 leading-normal ">
						<span className="font-bold">Du hast noch Fragen zur Aktion? Kein Problem!</span>
						<br />
						Kontaktiere uns einfach per E-Mail unter info@purmacherei-probieren.de
						<br />
						<br />
						Besuche uns gerne auf{" "}
						<a href="https://purmacherei.de" target="_blank" className="underline" rel="noreferrer">
							purmacherei.de
						</a>
						, um nichts zu verpassen.
					</p>

					<img src={process.env.PUBLIC_URL + "/images/promoover.png"} className=" w-full max-w-[845px] mx-auto relative z-10 pt-20 " alt="" />
				</div>
			</div>
		</div>
	)
}
export default Promoover
