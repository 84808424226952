import { useContext } from "react"
import { MAX_PRODUCT_PRICE } from "../../config"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemSelect from "../elements/Select"
import SelectPackage from "./SelectPackage"

interface ProductsProps {
	formElementChanged: Function
	validation: Object
}
const Product: React.FC<ProductsProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<div className=" pt-16">
			<h2 className="h2 text-center text-yellow ">Welches Produkt hast du probiert?</h2>

			<SelectPackage {...{ formElementChanged }} />

			<div className="container">
				<div className="grid gap-5 grid-cols-1 md:grid-cols-2 items-start mb-6 max-w-[600px] mx-auto">
					<ElemInput
						label={"Kaufbetrag:*"}
						props={{
							type: "number",
							step: "0.01",
							min: "0.1",
							max: MAX_PRODUCT_PRICE,
							name: "purchaseAmount",
							value: globalState.purchaseAmount,
							required: "required",
							onChange: e => formElementChanged(e, "purchaseAmount"),
						}}
						hasError={validation["invalid_purchaseAmount"] ? true : false}
						className="col-span-2 md:col-span-1"
						suffix="EUR"
						errMsg={validation["invalid_purchaseAmount_errMsg"] || "Bitte Kaufbetrag eingeben. (max. " + MAX_PRODUCT_PRICE + ",00 EUR)"}
					/>
					<ElemInput
						label={"Bonsumme:*"}
						props={{
							type: "number",
							step: "0.01",
							min: "0.1",
							name: "purchaseTotal",
							value: globalState.purchaseTotal,
							required: "required",
							onChange: e => formElementChanged(e, "purchaseTotal"),
						}}
						suffix="EUR"
						hasError={validation["invalid_purchaseTotal"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_purchaseTotal_errMsg"] || "Bitte Bonsumme eingeben."}
					/>
					<ElemInput
						label={"Kaufdatum:*"}
						props={{
							type: "date",
							name: "purchaseDate",
							value: globalState.purchaseDate,
							required: "required",
							minLength: 1,
							onChange: e => formElementChanged(e, "purchaseDate"),
							min: "2024-01-02",
							max: "2024-09-30",
						}}
						hasError={validation["invalid_purchaseDate"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_purchaseDate_errMsg"] || "Bitte Kaufdatum eingeben."}
					/>

					<ElemSelect
						label={"Einkaufsstätte:*"}
						props={{
							name: "merchant",
							value: globalState.merchant,
							onChange: e => formElementChanged(e, "merchant"),
							required: "required",
						}}
						hasError={validation["invalid_merchant"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_merchant_errMsg"] || "Bitte Einkaufsstätte angeben."}
					>
						<>
							<option value="">bitte wählen</option>
							<option value="a">EDEKA</option>
							<option value="b">REWE</option>
							<option value="c">BUDNI</option>
							<option value="d">ANDERE</option>
						</>
					</ElemSelect>
				</div>
			</div>
		</div>
	)
}

export default Product
