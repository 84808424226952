import React, { useEffect } from "react"
import Header from "../components/Header"

const Confirm: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green relative">
			<div className="absolute left-[60%] top-[0%] w-[40%] h-[70%] bg-[length:140%_140%] bg-left-bottom bg-[url('/src/bgimages/kreis.svg')] bg-no-repeat opacity-50"></div>
			<div className="absolute left-[0%] top-[20%] w-[30%] h-[70%] bg-[length:auto_100%] bg-right bg-[url('/src/bgimages/kreis.svg')] bg-no-repeat opacity-50"></div>

			<div className="text-center relative  pt-20 xl:pt-0">
				<Header addClass="text-white" />
				<div className=" max-w-[1400px]  mx-auto">
					<h2 className="h2 text-white px-4">Vielen Dank für die Teilnahme an unserer HaferHaps Probier-Rabatt-Aktion!</h2>
					<img src={process.env.PUBLIC_URL + "/images/danke.png"} className=" w-full max-w-[854px] mx-auto relative z-10 pt-10 " alt="" />

					<p className="text-white text-sm sm:text-lg pt-4  font-Quicksand px-4">
						Deine Daten wurden erfolgreich übermittelt. Du erhältst per E-Mail eine Eingangsbestätigung. <br />
						Anschließend werden wir die Einhaltung der Teilnahmevoraussetzungen prüfen.
						<br />
						<br />
						Viele Grüße
						<br />
						Dein PURmacherei Team
					</p>
					<div className="text-center  pb-10">
						<a href="/" className="inline-block btn btn-invert mt-8">
							Zurück zur Aktion
						</a>
					</div>

					<div className="text-center py-20">
						<p className="text-white text-sm sm:text-lg pt-4  font-Quicksand px-4">
							Du willst neueste Infos zur PURmacherei? <br />
							Abonniere unseren Newsletter
						</p>
						<a href="https://www.purmacherei.de/pages/newsletter" className="btn-invert inline-block mt-4 sm:mt-8 mb-20 sm:mb-12">
							Newsletter abonnieren
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Confirm
