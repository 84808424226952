import React, { useEffect } from "react"
import Hero from "../components/home/Hero"
import Receipt from "../components/receipt/Receipt"
import Sogehts from "../components/home/Sogehts"
import Form from "../components/Forms/Form"
import { getPromoLimit } from "../env"
import { useNavigate } from "react-router-dom"

const Home: React.FC = () => {
	const navigate = useNavigate()
	useEffect(() => {
		getPromoLimit(data => {
			if (!!data) {
				navigate("/abgelaufen")
			}
		})
	}, [])

	return (
		<>
			<Hero />
			<Sogehts />
			<Form />
		</>
	)
}
export default Home
