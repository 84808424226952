import Header from "../Header"

const Hero = () => {
	return (
		<>
			<div className="bg-beige relative">
				<img src={process.env.PUBLIC_URL + "/images/herobogen.png"} className="block w-full absolute top-0 left-0 z-0 pointer-events-none" alt="" />

				<a href="https://www.purmacherei.de/" rel="nofollow" target="_blank" className="  max-w-[118px] sm:max-w-[241px] block mx-auto  relative z-10 pt-7">
					<img src={process.env.PUBLIC_URL + "/images/Logo.png"} className="w-full " alt="" />
				</a>

				<Header />

				<picture className="">
					<source media="(min-width: 640px)" srcSet={process.env.PUBLIC_URL + "/images/heropacks.png"} />
					<img src={process.env.PUBLIC_URL + "/images/heropacks-mobile.png"} className="pb-4 sm:pb-0  w-full max-w-[1452px] mx-auto relative z-10 " alt="" />
				</picture>
			</div>
			<div className="h-0 max-w-[1452px] mx-auto text-right relative z-20">
				<img
					src={process.env.PUBLIC_URL + "/images/keks2.png"}
					className=" w-full max-w-[140px] sm:max-w-[313px] rotate-90 sm:rotate-0 sm:ml-auto -translate-y-[50%] sm:-translate-y-[70%]"
					alt=""
				/>
			</div>
		</>
	)
}

export default Hero
