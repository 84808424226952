import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemSelect from "../elements/Select"

interface PersonProps {
	formElementChanged: Function
	validation: Object
}
const Person: React.FC<PersonProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)

	return (
		<section className="container px-4">
			<h2 className="h3 text-center  pt-11 pb-11">Angaben zu deiner Person</h2>

			<div className="grid gap-5 grid-cols-1 md:grid-cols-[1fr_1fr] items-start mb-2 sm:mb-12 max-w-[600px] mx-auto">
				<ElemSelect
					label={"Anrede:"}
					props={{
						name: "salutation",
						value: globalState.salutation,
						onChange: e => formElementChanged(e, "salutation"),
					}}
					hasError={validation["invalid_salutation"] ? true : false}
					className="col-span-2 md:col-span-1"
					errMsg={validation["invalid_salutation_errMsg"] || "Bitte Anrede angeben."}
				>
					<option value="4">bitte wählen</option>
					<option value="1">Herr</option>
					<option value="2">Frau</option>
					<option value="3">divers</option>
				</ElemSelect>

				<ElemInput
					label={"Vorname:*"}
					props={{
						type: "text",
						name: "firstname",
						value: globalState.firstname,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "firstname"),
					}}
					hasError={validation["invalid_firstname"] ? true : false}
					className="col-span-2 md:col-span-1"
					errMsg={validation["invalid_firstname_errMsg"] || "Bitte Vornamen eingeben."}
				/>

				<ElemInput
					label={"Nachname:*"}
					props={{
						type: "text",
						name: "surname",
						value: globalState.surname,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "surname"),
					}}
					hasError={validation["invalid_surname"] ? true : false}
					className="col-span-2 md:col-span-1"
					errMsg={validation["invalid_surname_errMsg"] || "Bitte Nachnamen eingeben."}
				/>
				<ElemInput
					className="col-span-2 md:col-span-1"
					label={"E-Mail-Adresse:*"}
					props={{
						type: "email",
						name: "email",
						value: globalState.email,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "email"),
					}}
					hasError={validation["invalid_email"] ? true : false}
					errMsg={validation["invalid_email_errMsg"] || "Bitte E-Mail-Adresse eingeben."}
				/>
			</div>
		</section>
	)
}

export default Person
