import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ERRORTYPES, MAX_PRODUCT_PRICE } from "../../config"
import { Context } from "../../store/store"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"
import BankAccount from "./BankAccount"
import Legal from "./Legal"
import { placeOrder } from "./OrderFct"
import Person from "./Person"
import Product from "./Product"

import Receipt from "../receipt/Receipt"
import PreFillForm from "./PrefillForm"
import SimpleCaptcha from "./SimpleCaptcha"
import Survey from "./Survey"

const Form = () => {
	const { globalState, dispatch } = useContext(Context)
	const [validation, setValidation] = useState({})
	const navigate = useNavigate()
	const [formError, setFormError] = useState<ERRORTYPES | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function formElementChanged(e, key) {
		// special handling for checkboxes
		if (e.target.type === "checkbox") {
			e.target.value = e.target.checked
		}

		dispatch({
			type: "SET_ORDER_ENTRY",
			key: key,
			value: e.target.value
				.replace(/(<([^>]+)>)/gi, "")
				.replace("https://www", "")
				.replace("mailto:", ""),
		}) // strip HTML tags

		let isFieldInvalid = false

		const doc: any = document
		const elem = doc.querySelector("[name='" + key + "']:valid")
		if (elem === null) {
			isFieldInvalid = true
		}

		// check for price maximum
		if (key === "purchaseAmount") {
			const price = parseFloat(e.target.value.replace(",", "."))
			if (price > MAX_PRODUCT_PRICE) {
				isFieldInvalid = true
			}
		}

		// check validation
		setValidation({ ...validation, ["invalid_" + key]: isFieldInvalid })
	}

	function submitOrder() {
		// create order part
		const orderSuccessCallback = extId => {
			setIsLoading(false)
			if (extId) {
				navigate("/bestaetigung")
			} else {
				setFormError(ERRORTYPES.SERVER)
			}
		}
		const orderErrCallback = (tooManyAttemps: false, invalidCaptcha: false) => {
			setIsLoading(false)

			// trigger reset Captcha
			const doc: any = window.document
			doc.querySelector(".js-reloadCaptcha").click()

			//TODO: Handling needs to be switched to error codes (BE part still open)
			if (tooManyAttemps) {
				setFormError(ERRORTYPES.MAXTRIES)
			} else if (invalidCaptcha) {
				setValidation({ ...validation, invalid_captcha: true })
				setFormError(null)
			} else {
				setFormError(ERRORTYPES.SERVER)
			}
		}

		if (allInputFieldsValid()) {
			if (globalState.receiptReference) {
				setIsLoading(true)
				setFormError(null)
				placeOrder(globalState, setFormValidation, orderSuccessCallback, orderErrCallback)
			} else {
				setFormError(ERRORTYPES.UPLOADSMISSINGRECEIPT)
			}
		} else {
			setFormError(ERRORTYPES.FORMVALIDATION)
			scrollToError()
		}
	}

	function isBicRequired() {
		return globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE"
	}

	function allInputFieldsValid() {
		// input names has to fit to variable classNames
		let isAllValid = true
		let invalidFields = {}

		// Special handing for bic. Bic is only for non DE IBANs required
		let fieldsToCheck: any = []
		if (isBicRequired()) {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "package" &&
					e !== "captcha" &&
					e !== "sessionID" &&
					e !== "question1" &&
					e !== "question2" &&
					e !== "captchaIdent" &&
					e !== "receiptReference" &&
					e !== "newsletter" &&
					e !== "receiptFileName"
			)
		} else {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "package" &&
					e !== "captcha" &&
					e !== "sessionID" &&
					e !== "question1" &&
					e !== "question2" &&
					e !== "captchaIdent" &&
					e !== "receiptReference" &&
					e !== "receiptFileName" &&
					e !== "newsletter" &&
					e !== "bic"
			)
		}

		for (let index = 0; index < fieldsToCheck.length; index++) {
			const key = fieldsToCheck[index]
			const doc: any = document
			const elem = doc.querySelector("[name='" + key + "']:valid")

			if (elem === null) {
				isAllValid = false
				invalidFields["invalid_" + key] = true
				console.log("invalid_" + key)
			}
		}

		if (Object.keys(invalidFields).length) {
			setValidation({ ...validation, ...invalidFields })
		}

		return isAllValid
	}

	function setFormValidation(data: {}) {
		setValidation({
			...validation,
			...data,
		})
		scrollToError()
	}

	function scrollToError() {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-formerror").getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 120,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 500)
	}

	return (
		<form
			onSubmit={e => {
				e.preventDefault()
				submitOrder()
			}}
			noValidate={true}
		>
			<section className="pt-12 sm:pt-32 js-mitmachen relative bg-beige">
				<PreFillForm {...{ formElementChanged }} />
				<div className=" relative z-10">
					<div className="absolute left-[60%] -top-12 sm:-top-32 w-[40%] h-[70%] bg-[length:220%_220%] bg-left-bottom bg-[url('/src/bgimages/kreisBeige.svg')] bg-no-repeat  pointer-events-none"></div>
					<div className="absolute left-[0%] top-[20%] w-[30%] h-[80%] bg-[length:220%_220%] bg-right-top bg-[url('/src/bgimages/kreisBeige.svg')] bg-no-repeat  pointer-events-none"></div>
					<Receipt />

					<Person {...{ validation, formElementChanged }} />
				</div>
				<div className="h-0 max-w-[1452px] mx-auto text-right relative z-0">
					<img src={process.env.PUBLIC_URL + "/images/keks2.png"} className="block sm:hidden w-full max-w-[140px]  -translate-y-[50%] pointer-events-none" alt="" />
					<img src={process.env.PUBLIC_URL + "/images/keks1.png"} className="hidden sm:block w-full max-w-[243px] ml-auto -translate-y-[90%]" alt="" />
				</div>
			</section>

			<section className="bg-brown px-4 relative">
				<div className="absolute left-[0%] sm:left-[60%] top-[20%] w-[100%] sm:w-[40%] h-[60%] bg-[length:auto_100%] bg-center sm:bg-left-bottom bg-[url('/src/bgimages/kreis.svg')] bg-no-repeat opacity-20 pointer-events-none"></div>

				<Product {...{ validation, formElementChanged }} />
				<BankAccount {...{ validation, formElementChanged }} />
				<Survey {...{ validation, formElementChanged }} />
				<Legal {...{ validation, formElementChanged }} />

				{/* <div className="text-center py-6">
					<p className="text-white text-sm sm:text-lg pt-4  font-Quicksand px-4">
						Du willst neueste Infos zur PURmacherei? <br />
						Abonniere unseren Newsletter
					</p>
					<a href="https://www.purmacherei.de/newsletter" className="btn-invert inline-block mt-4 sm:mt-8 scale-75">
						Newsletter abonnieren
					</a>
				</div> */}

				<SimpleCaptcha {...{ validation, formElementChanged }} />

				<div className="pt-4 sm:pt-16 pb-12 sm:pb-[132px] text-center " ref={animationRef}>
					{formError && <Alert className={"my-4"}>{formError}</Alert>}
					<button type="submit" className="btn" disabled={isLoading ? true : undefined}>
						{isLoading && <Spinner />}
						jetzt teilnehmen
					</button>
				</div>
			</section>
		</form>
	)
}

export default Form
