import { useState } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { Link } from "react-router-dom"
import MobileMenu from "./MobileMenu"

const Header = ({ isPromoSoon = false, addClass = "" }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function scrollToAnchor(anchor) {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-" + anchor).getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 300)
	}

	return (
		<>
			<div ref={animationRef}>{showMenu && <MobileMenu setShowMenu={setShowMenu} isPromoSoon={isPromoSoon} scrollToAnchor={scrollToAnchor} />}</div>

			<header className="xl:pt-24 xl:pb-28">
				<ul className="z-20 relative hidden xl:block text-center">
					<>
						<li className="header-li">
							<Link to="/" onClick={() => scrollToAnchor("sogehts")} className={"header-item " + addClass}>
								So geht's
							</Link>
						</li>
						<li className="header-li">
							<Link to="/" onClick={() => scrollToAnchor("mitmachen")} className={"header-item " + addClass}>
								Jetzt mitmachen
							</Link>
						</li>
					</>
					<li className="header-li">
						<Link to={process.env.PUBLIC_URL + "/teilnahmebedingungen"} className={"header-item " + addClass}>
							Teilnahmebedingungen
						</Link>
					</li>
					<li className="header-li">
						<Link to={process.env.PUBLIC_URL + "/faq"} className={"header-item " + addClass}>
							FAQ
						</Link>
					</li>
					<li className="header-li">
						<Link to={process.env.PUBLIC_URL + "/kontakt"} className={"header-item " + addClass}>
							Kontakt
						</Link>
					</li>
				</ul>
				<div className="block xl:hidden text-right relative -top-9  pr-6">
					<img
						src={process.env.PUBLIC_URL + "/images/icon-menu.png"}
						alt="Menu"
						loading="lazy"
						width="33"
						className=" w-[33px] float-right  cursor-pointer"
						onClick={() => setShowMenu(true)}
					/>
				</div>
			</header>
		</>
	)
}

export default Header
