import { useContext } from "react"
import { Context } from "../../store/store"
import ElemRadio from "../elements/Radio"

interface SurveyProps {
	formElementChanged: Function
	validation: Object
}
const Survey: React.FC<SurveyProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<section className="container">
			<h2 className="h3 text-yellow  text-center ">Bevor du absendest</h2>

			<div className=" ">
				<ul className="max-w-[732px] mx-auto pt-10 pb-8 text-brown font ">
					<li>
						<div className="font-bold text-yellow ">Bitte gib dein Alter an:</div>
						<div className="grid grid-cols-2 sm:grid-cols-4 sm:gap-4">
							<ElemRadio
								label={"Unter 18 Jahre"}
								props={{
									name: "quest1",
									value: "a",
									type: "radio",
									onChange: e => formElementChanged(e, "question1"),
									checked: "a" === globalState.question1,
								}}
							></ElemRadio>
							<ElemRadio
								label={"18 bis 29 Jahre"}
								props={{
									name: "quest1",
									value: "b",
									type: "radio",
									onChange: e => formElementChanged(e, "question1"),
									checked: "b" === globalState.question1,
								}}
							></ElemRadio>
							<ElemRadio
								label={"30 bis 54 Jahre"}
								props={{
									name: "quest1",
									value: "c",
									type: "radio",
									onChange: e => formElementChanged(e, "question1"),
									checked: "c" === globalState.question1,
								}}
							></ElemRadio>
							<ElemRadio
								label={"55 Jahre oder älter"}
								props={{
									name: "quest1",
									value: "d",
									type: "radio",
									onChange: e => formElementChanged(e, "question1"),
									checked: "d" === globalState.question1,
								}}
							></ElemRadio>
						</div>
					</li>
					<li className="mt-6 sm:mt-0">
						<div className="font-bold text-yellow ">Bitte verrate uns noch dein Geschlecht:</div>
						<div className="grid grid-cols-2 sm:grid-cols-4 sm:gap-4">
							<ElemRadio
								label={"Weiblich"}
								props={{
									name: "quest2",
									value: "a",
									type: "radio",
									onChange: e => formElementChanged(e, "question2"),
									checked: "a" === globalState.question2,
								}}
							></ElemRadio>
							<ElemRadio
								label={"Männlich"}
								props={{
									name: "quest2",
									value: "b",
									type: "radio",
									onChange: e => formElementChanged(e, "question2"),
									checked: "b" === globalState.question2,
								}}
							></ElemRadio>
							<ElemRadio
								label={"Divers"}
								props={{
									name: "quest2",
									value: "c",
									type: "radio",
									onChange: e => formElementChanged(e, "question2"),
									checked: "c" === globalState.question2,
								}}
							></ElemRadio>
							<ElemRadio
								label={"keine Angabe"}
								props={{
									name: "quest2",
									value: "d",
									type: "radio",
									onChange: e => formElementChanged(e, "question2"),
									checked: "d" === globalState.question2,
								}}
							></ElemRadio>
						</div>
					</li>
				</ul>
			</div>
		</section>
	)
}

export default Survey
