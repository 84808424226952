import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
	suffix?: string
}

const ElemInput: React.FC<InputProps> = ({ className, props, label, onClick, errMsg, showLeftCount, hasError, suffix }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<input
				className={
					(props.type === "date" ? "" : " peer ") +
					" font-semibold  pt-4 w-full rounded-[13px] px-4 text-base  text-brown h-[47px] leading-[47px] placeholder-transparent outline-0 appearance-none bg-white " +
					(hasError ? "  js-formerror " : "") +
					(props.type === "date" ? " uppercase" : "")
				}
				style={{ boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset" }}
				onClick={onClick}
				{...props}
			/>
			<span
				className={
					(props.type === "date" ? "-translate-y-2 " : "  peer-focus:-translate-y-2 transition ") +
					(!!props.value ? "-translate-y-2" : "") +
					" pointer-events-none absolute left-0 top-0 font-Quicksand text-base px-4 h-[47px] leading-[47px] pointer-events-none " +
					(hasError ? "  text-red " : " text-brown ")
				}
			>
				{label}
			</span>
			{suffix && <div className="absolute top-3 right-3 text-brown">{suffix}</div>}
			{showLeftCount && props.maxLength && (
				<div className="absolute top-3 right-3 text-brown">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			<div ref={animationRef}>{hasError && <div className="formErrorMsg ">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemInput

// https://play.tailwindcss.com/tKldzVn3U1
