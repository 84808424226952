import { useContext, useEffect, useState } from "react"
import { getEnvConfig } from "../../env"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"

const SimpleCaptcha = ({ formElementChanged, validation }) => {
	const { globalState, dispatch } = useContext(Context)
	const env = getEnvConfig()
	const [captchaImg, setCaptchaImg]: any = useState("")

	const toDataURL = url => {
		return fetch(url)
			.then(response => {
				response.headers.forEach(function (value, key) {
					if (key === "x-captcha-ident") {
						dispatch({
							type: "SET_ORDER_ENTRY",
							key: "captchaIdent",
							value: value,
						})
					}
					if (key === "session-id") {
						dispatch({
							type: "SET_ORDER_ENTRY",
							key: "sessionID",
							value: value,
						})
					}
				})

				return response.blob()
			})
			.then(
				blob =>
					new Promise((resolve, reject) => {
						const reader = new FileReader()
						reader.onloadend = () => resolve(reader.result)
						reader.onerror = reject
						reader.readAsDataURL(blob)
					})
			)
	}

	useEffect(() => {
		reloadCaptcha()
		// eslint-disable-next-line
	}, [])

	function reloadCaptcha() {
		toDataURL(env.apibase + "captcha.php?cv=" + Date.now()).then(dataUrl => {
			setCaptchaImg(dataUrl)
		})
	}

	return (
		<section className="container">
			<div className=" my-6 max-w-[600px] mx-auto">
				<div className="mb-4 font-Quicksand grid grid-cols-1 sm:grid-cols-2 gap-4 items-top">
					<div className="text-center">
						<img src={captchaImg} className="rounded-lg w-full max-w-[180px] mx-auto" alt="Captchaimage" />
						<div className="underline cursor-pointer js-reloadCaptcha text-yellow text-[15px]" onClick={reloadCaptcha}>
							Captcha neu laden
						</div>
					</div>
					<div className="text-xl leading-xl font-Quicksand text-brown pb-3">
						<ElemInput
							label={"Captcha Code eingeben:*"}
							props={{
								value: globalState.captcha,
								required: "required",
								minLength: 2,
								maxLength: 10,
								onChange: e => formElementChanged(e, "captcha"),

								id: "captcha",
								name: "captcha",
								type: "text",
							}}
							hasError={validation["invalid_captcha"] ? true : false}
							className="col-span-2 md:col-span-1"
							errMsg="Bitte Captcha korrekt eingeben."
						/>
					</div>
				</div>
			</div>
			<div className="max-w-[942px] mx-auto">
				<div className="text-left  text-yellow font-Quicksand">*Pflichtfelder</div>
			</div>
		</section>
	)
}

export default SimpleCaptcha
