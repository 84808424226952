import React, { createContext, useReducer } from "react"
import { PACKAGES } from "../config"
import Reducer from "./reducer"

const initialState = {
	salutation: "4",
	firstname: "",
	surname: "",
	email: "",

	purchaseAmount: "",
	purchaseTotal: "",
	purchaseDate: "",
	merchant: "",

	bankName: "",
	iban: "",
	bic: "",

	terms: "",
	age: "",
	newsletter: "",

	captcha: "",
	captchaIdent: "",
	sessionID: null,

	package: Object.keys(PACKAGES)[0],

	receiptReference: null,
	receiptFileName: null,
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
