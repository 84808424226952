import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"

const Privacy: React.FC = () => {
	const navigate = useNavigate()

	// useEffect(() => {
	// 	window.scrollTo({
	// 		top: 0,
	// 		left: 0,
	// 		behavior: "smooth",
	// 	})
	// }, [])

	return (
		<div className="bg-green">
			<Header addClass="text-white" />
			<div>
				<div className="container max-w-[800px] mx-auto px-4  text-white">
					<h2 className="h2 pt-12 text-white ">Datenschutz</h2>

					<div>
						<h3 className="h3 font-Grota text-[20px] text-white">Der Schutz Ihrer Privatsphäre ist uns wichtig</h3>
						<p className="mb-4">
							Es ist für uns eine Selbstverständlichkeit, dass wir verantwortungsvoll mit Ihren personenbezogenen Daten umgehen, wenn Sie unsere Website und unseren Online-Shop
							nutzen und elektronisch mit uns kommunizieren. Wir ergreifen alle erforderlichen Maßnahmen, um die von Ihnen zur Verfügung gestellten personenbezogenen Daten zu
							schützen und die geltenden nationalen und europäischen Datenschutzvorschriften einzuhalten.
						</p>
						<p className="mb-4">
							Im Rahmen dieser Datenschutzerklärung informieren wir Sie über die Art und Weise, wie wir Ihre personenbezogenen Daten verarbeiten. Bitte beachten Sie, dass die
							verantwortliche Stelle für Ihre personenbezogenen Daten im Rahmen einer Website, diejenige ist, die im Impressum der jeweiligen Website genannte Gesellschaft ist.
						</p>
						<p className="mb-4">Kontakt Datenschutzbeauftragter:</p>
						<p className="mb-4">
							Per E-Mail:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="mailto:datenschutz@purmacherei.de">
								datenschutz@purmacherei.de
							</a>
							<br />
							Per Post: Hang zur Sonne GmbH, Datenschutz, Alfred-Ritter-Str. 25, D-71111 Waldenbuch
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Welche Daten werden erhoben und wie werden sie erhoben, wenn Sie diese Website besuchen?</h3>

						<p className="mb-4">
							Wir erfassen nur personenbezogene Daten, die Sie uns zur Verfügung stellen möchten oder die erforderlich sind, damit wir Ihnen unseren Dienst bereitstellen können.
							Wenn Sie unsere Website besuchen, speichern unsere Webserver standardmäßig unter anderem Angaben zu dem von Ihnen verwendeten Browser und Betriebssystem, die Website,
							von der aus Sie uns besuchen, die Websites, die Sie bei uns besuchen sowie die Dauer des Besuchs, das Datum des Besuches sowie aus Sicherheitsgründen, z.B. zur
							Erkennung von Angriffen auf unsere Websites, und für die Dauer eines Monats die Internet-Protocol-Adresse (IP-Adresse), die Ihnen von Ihrem Internet Service Provider
							zugewiesen wurde. Sonstige personenbezogene Daten werden dann gespeichert, wenn Sie uns diese von sich aus angeben. So stellen Sie uns Ihre E-Mail-Adresse, Ihren
							Namen, Ihre Postadresse, Ihre Telefonnummer, Zahlungsdaten sowie ggf. weitere Daten zur Verfügung, um Informationen zu unseren Produkten zu erhalten, an Gewinnspielen
							oder Umfragen teilzunehmen, Produkte zu kaufen oder einfach auf dem neusten Stand bezüglich der für Sie interessanten Produkte zu sein.
						</p>
						<p className="mb-4">
							Wenn Sie unsere Website aufrufen und Sie in Freitextfelder etwas eintragen und z.B. kommentieren, speichern unsere Systeme die Internet-Protocol-Adresse (IP-Adresse).
							Wir benötigen diese Information aus Sicherheitsgründen, um Missbrauch vorzubeugen und für andere Zwecke der Gefahrenabwehr.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Sicherheit</h3>

						<p className="mb-4">
							Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre durch uns verwalteten Daten gegen Manipulationen, Verlust, Zerstörung und gegen den
							Zugriff unberechtigter Personen zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
						</p>
						<p className="mb-4">
							Nur autorisierte Mitarbeiter/innen haben Zugriff auf Ihre personenbezogenen Daten. Dies können gegebenenfalls auch autorisierte Mitarbeiter/innen unserer
							Dienstleister sein, die im Auftrag von uns die Daten verarbeiten oder Zugriff auf diese Daten haben. Für diesen Fall haben wir mit dem Dienstleister einen sogenannten
							„Auftragsdatenverarbeitungsvertrag“ geschlossen.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Einkauf über unseren Online-Shop</h3>

						<p className="mb-4">
							Beim Online Einkauf von Produkten über unseren Online-Shop werden Ihre personenbezogenen Daten nur verschlüsselt übertragen. Für jeden Datentransfer verwenden wir das
							sogenannte TLS-Sicherheitssystem (Transport Layer Security) in Verbindung mit einer 128-Bit-Verschlüsselung. Diese Technik bietet höchste Sicherheit. Die
							verschlüsselten Angaben zu Ihrer Bestellung, Ihrem Namen, Ihrer Adresse oder Ihrer Zahlungsdaten können Außenstehende nicht lesen.
						</p>
						<p className="mb-4">
							Die Zahlungsabwicklung folgt ebenfalls unter den Maßgaben der höchsten Sicherheitsanforderungen. Sämtliche Datenübermittlung erfolgt bis zu 256 Bit TLS (Transport
							Layer Security) verschlüsselt und ist dadurch vor dem unbefugten Zugriff durch Dritte geschützt. Darüber hinaus werden ihre Kreditkartendaten nach den strengsten
							Sicherheitsauflagen der Kreditkartenindustrie, dem sogenannten Payment Card Industry Data Security Standard (kurz PCI DSS), verarbeitet und gespeichert. Personen- wie
							auch kartenbezogene Daten sind somit nach dem höchsten Sicherheitsstandard auch im Sinne des Datenschutzes geschützt.
						</p>
						<p className="mb-4">
							Für den Bezahlvorgang nutzen wir den Zahlungsdienstleister Paypal. Hierzu leiten wir Ihren Namen, Ihre Lieferadresse, Ihre E-Mail-Adresse und die Warenkorbdetails
							sowie je nach Bezahlmethode (Paypal, Kreditkarte und SEPA-Lastschrift) auch Konto- und Kreditkartendaten an PayPal weiter damit PayPal Ihre Bestellung korrekt
							zuordnen und den Bezahlvorgang durchführen kann.
						</p>
						<p className="mb-4">
							Wenn Sie per Kreditkarte (VISA, MasterCard, American Express) bezahlen, werden Sie aufgefordert die Daten auf der Kreditkarte anzugeben. Diese umfassen die
							Kreditkarten-Nummer, den Vor- und Nachnamen des Karteninhabers/der Karteninhaberin, das Ablaufdatum der Kreditkarte in Form von Monat/Jahr und die
							Verifizierungsnummer der Kreditkarte (CVC/CVV). Diese Daten werden ebenfalls an Paypal für entsprechende Zahlungsaufträge durchgeleitet, die diese im Auftrag von uns
							eigenständig auf Gültigkeit überprüfen. Wir selbst haben hierbei keinen Zugriff auf oder Einblick in die Daten. Die Zahlungsdaten werden hierbei nach den
							technologisch höchsten Standards verschlüsselt.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Für welche Zwecke verwenden wir Ihre personenbezogene Daten?</h3>

						<p className="mb-4">
							Wir nutzen Ihre personenbezogenen Daten zu Zwecken der technischen Administration der Website, zur Kundenverwaltung, zur Beantwortung Ihrer Fragen im Rahmen des
							Verbraucherservice, für Produkt an- und umfragen, und für das Marketing nur im jeweils dafür erforderlichen Umfang. Ihre persönlichen Daten nutzen wir nur für die
							Zwecke, für die Sie sie uns zur Verfügung gestellt haben. Darunter fällt beim Online-Shop auch die Erfüllung und Abwicklung Ihrer Bestellungen.
						</p>
						<p className="mb-4">
							Sofern Sie uns eine Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten gegeben haben, werden diese Daten darüber hinaus zu den in der entsprechenden
							Einwilligung genannten Zwecken, z.B. Newsletter-Versand, Zusenden von Produkten genutzt. Ihre Einwilligung dazu können Sie jederzeit für die Zukunft widerrufen.
						</p>
						<p className="mb-4">
							Im Rahmen der individuellen Nutzung möchten wir unsere Werbemittel, wie z. B. unseren Newsletter und Ihren Besuch auf unseren Websites so bedienungsfreundlich und
							individuell wie möglich gestalten. Dabei helfen uns automatisch erzeugte und von Ihnen übermittelte Informationen, um für Sie individuelle und passgenaue
							Informationen bereitzustellen. Bei diesen Informationen handelt es sich u.a. um „technische Daten“ (z.B. IP-Adresse, das Betriebssystem, Browser), Datum und Zeitpunkt
							des Besuchs unserer Website und „Daten zu Ihrem (Kauf-)Verhalten“ (Servicehistorie, Bestellhistorie, von Ihnen angesehene Seiten und Produkte).
						</p>
						<p className="mb-4">
							Wenn Sie unseren Newsletter abonniert haben, werden uns automatisch „technische Informationen“ zur Verfügung gestellt, die es uns z.B. ermöglichen auszuwerten, wie
							oft der Newsletter geöffnet wurde und welche Inhalte wie häufig angeklickt wurden (z.B. Öffnungsrate und Klickrate). Die Analyse dieser Informationen ermöglicht uns
							ferner, unsere digitalen Angebote, z. B. unseres Newsletters zu optimieren. und mit Ihnen möglichst individuell zu kommunizieren. Damit wollen wir erreichen, dass Sie
							nur solche Inhalte erhalten, die Sie auch wirklich interessieren.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Übermittlung Ihrer Daten an Dritte und in andere Länder</h3>

						<p className="mb-4">
							Wir stellen Ihre personenbezogenen Daten keinen anderen Unternehmen zur Verfügung, die diese für Direktmarketingmaßnahmen verwenden möchten, es sei denn, Sie haben
							uns die Erlaubnis dafür gegeben. Des Weiteren stellen wir in einem stark begrenztem Rahmen folgenden anderen Stellen Ihre personenbezogenen Daten zur Verfügung:
						</p>
						<ul className="text-white list-disc ml-6 pb-6">
							<li>
								Unseren Dienstleistern, damit diese uns z.B. bei der Verwaltung unserer Website oder uns bei der Abwicklung der Online-Shop-Bestellungen und dem Versand der
								Produkte und Newsletter unterstützen können. Diese Unternehmen haben wir vertraglich verpflichtet, dieselben Sicherheitsmaßnahmen wie wir im Hinblick auf die
								personenbezogenen Daten zu ergreifen, die Vertraulichkeit der Daten zu wahren und sie nur auf ausdrückliche Anweisung von uns zu verarbeiten.
							</li>
							<li>
								Anderen Unternehmen innerhalb unseres Konzerns, sofern Sie dazu eingewilligt haben oder diese Unternehmen als unsere Dienstleister in unserem Auftrag arbeiten und
								wir diese wie unsere externen Dienstleister vertraglich verpflichtet haben.
							</li>
							<li>Übermittlungen personenbezogener Daten an staatliche Einrichtungen und Behörden erfolgen nur im Rahmen zwingender geltender Rechtsvorschriften.</li>
						</ul>
						<p className="mb-4">
							Ihre personenbezogenen Daten können dann in andere Länder übermittelt werden, wenn ein Dienstleister von uns seinen Sitz im Ausland hat. Für diesen Fall wird mit dem
							Dienstleister ein sogenannter „Auftragsverarbeitungsvertrag“ geschlossen, der sicherstellt, dass der gleiche Grad an Datenschutz und Datensicherheit gegeben ist, der
							in Deutschland herrscht. Dies wird unter anderem dadurch gewährleistet, dass der Dienstleister verpflichtet wird, ausschließlich auf Anweisung von uns zu handeln und
							fortlaufend alle technischen Maßnahmen zu ergreifen, die Ihre personenbezogenen Daten im gleichen Maße schützen wie hier.
						</p>
						<p className="mb-4">
							Sofern personenbezogene Daten in einem Land außerhalb der EU und des EWR verarbeitet werden, stellen wir das angemessene Datenschutzniveau z. B. durch den Abschluss
							des von der EU dafür vorgegebenen Standardvertrages.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Rechtsgrundlagen</h3>

						<p className="mb-4">
							Wenn Sie Produkte über unseren Online-Shop kaufen, ist die Verarbeitung Ihrer personenbezogenen Daten für die Erfüllung des mit Ihnen geschlossenen Kaufvertrages
							erforderlich. Ohne eine entsprechende Erhebung und Verarbeitung Ihrer Daten kann ein Online-Kauf nicht durchgeführt werden. Darüber hinaus werden Ihre
							personenbezogenen Daten aufgrund von Einwilligungserklärungen, die von Ihnen aktiv abgegeben wurden, z.B. für den Versand von Newslettern, verarbeitet.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Betroffenenrechte</h3>

						<p className="mb-4">Ihre Rechte in Bezug auf Ihre personenbezogenen Daten haben wir nachfolgend aufgeführt:</p>
						<p className="mb-4">
							Das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung sowie das Recht auf Datenübertragbarkeit.
						</p>
						<p className="mb-4">
							Sie haben auch das Recht, eine abgegebene Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten jederzeit für die Zukunft zu widerrufen.
						</p>
						<p className="mb-4">Um diese Rechte geltend zu machen, wenden Sie sich bitte an die eingangs genannten Kontaktdaten von unserem Datenschutzbeauftragten.</p>
						<p className="mb-4">
							Ergänzend möchten wir Sie darauf aufmerksam machen, dass in jedem unserer Newsletter für den Widerruf der Einwilligung hierzu die Möglichkeit gegeben ist, sich für
							diesen Dienst auszutragen und damit die abgegebene Einwilligung zu widerrufen.
						</p>
						<p className="mb-4">Eine telefonische Auskunft zu Ihren personenbezogenen Daten ist aufgrund der fehlenden eindeutigen Identifikation leider nicht möglich.</p>
						<p className="mb-4">Darüber hinaus besteht das Beschwerderecht bei einer Datenschutz-Aufsichtsbehörde.</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Cookies</h3>

						<p className="mb-4">
							Wenn Sie unsere Website nutzen, stimmen Sie der Nutzung und Speicherung von Cookies auf Ihrem Endgerät zu. Sie können unsere Website aber auch ohne Cookies
							betrachten. Die meisten Browser akzeptieren Cookies automatisch. Sie können das Speichern von Cookies auf Ihrem Endgerät verhindern, indem Sie in Ihren
							Browser-Einstellungen *keine Cookies akzeptieren* wählen. Auf Ihrem Endgerät können Sie bereits gesetzte Cookies jederzeit löschen. Wie das im Einzelnen funktioniert,
							entnehmen Sie bitte der Anleitung Ihres Browser bzw. Endgeräteherstellers. Wenn Sie verschiedene Computer an unterschiedlichen Standorten verwenden, müssen Sie
							sicherstellen, dass jeder Browser Ihren Präferenzen bezüglich Cookies entspricht.
						</p>
						<p className="mb-4">
							Cookies sind kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Das Session-Cookie (Sitzungscookie), dass wir für unsere Website nutzen, wird nach Ende der
							Browsersitzung wieder gelöscht.
						</p>
						<p className="mb-4">
							Wir nutzen Cookies, um z.B. Informationen wie Browsertyp und Betriebssystem, betreffende Seite, Pfad auf die Website usw. zu erfassen und die Nutzung dieser Website
							zu verstehen. Cookies helfen uns dabei, unsere Website an die Bedürfnisse unserer Verbraucher anzupassen und bestimmte Services und Funktionen, wie zum Beispiel auf
							Sie abgestimmte Informationen anzubieten.
						</p>
						<p className="mb-4">
							Personenbezogene Daten können dann in Cookies gespeichert werden, wenn Sie zugestimmt haben oder dies technisch unbedingt erforderlich ist, z.B. um einen geschützten
							Login zu ermöglichen.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Eingesetzte Cookies</h3>

						<p className="mb-4">Diese Cookies sind zwingend erforderlich, damit unsere Webseite und der Online-Shop sowie deren Funktionen ordnungsgemäß arbeiten.</p>
						<table className="table-fixed border-collapse text-sm text-left overflow-x-scroll block">
							<tbody>
								<tr className="hidden sm:table-row">
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Name des Cookie</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Zweck</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">wordpress_[hash]:</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">
											Beim Login wird dieser Cookie genutzt um Authentifizierungs-Details zu speichern und den Administrationsbereich (/wp-admin/) zu beschränken.
										</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">wordpress_logged_in_[hash]</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Nach dem Login wird dieser Cookie gesetzt, damit wir wissen, welcher Nutzer eingeloggt ist.</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">
											wp-settings-{"{"}time{"}"}-[UID]
										</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Diese Cookies werden benutzt, um die Oberfläche zu individualisieren.</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">wordpress_test_cookie</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Testet, ob der Browser Cookies akzeptiert um ein angemessenes Nutzererlebnis zu generieren.</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">
											wp-settings-{"{"}user_id{"}"}
										</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Wird verwendet, um die wp-admin-Konfiguration eines Benutzers beizubehalten.</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">woocommerce_cart_hash</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Dieser Cookie beinhaltet Informationen über den Warenkorb in WooCommerce. Es ermöglicht Veränderungen im Warenkorb nachzuvollziehen.</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">woocommerce_items_in_cart</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Dieser Cookie beinhaltet Informationen über den Warenkorb in WooCommerce. Es ermöglicht Veränderungen im Warenkorb nachzuvollziehen.</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">wp_woocommerce_session_[hash]</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">
											Dieser Cookie enthält einen eindeutigen Code für jeden Kunden, damit das System feststellen kann, wo die Warenkorbdaten in der Datenbank für jeden Kunden zu
											finden sind.
										</p>
									</td>
								</tr>
							</tbody>
						</table>

						<h3 className="h3 font-Grota text-[20px] text-white">Funktions-Cookies</h3>

						<p className="mb-4">Diese Cookies ermöglichen es, Komfort und Leistung von Websites zu verbessern und verschiedene Funktionen zur Verfügung zu stellen.</p>
						<table className="table-fixed border-collapse text-sm text-left overflow-x-scroll block">
							<tbody>
								<tr className="hidden sm:table-row">
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Name des Cookie</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Zweck</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">cookie_notice_accepted</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">
											Dieses Cookie zeigt an, dass Sie der Verwendung von Cookies auf unserem Online-Shop zustimmen. Es wird gesetzt, wenn Sie den Hinweislayer „Verwendung von
											Cookies“ schließen.
										</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">wfwaf-authcookie-[hash], wf_loginalerted_[hash], wfCBLBypass</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">
											Diese Cookies dienen der Sicherheit. Weitere Informationen finden Sie hier:{" "}
											<a className="underline" target="_blank" rel="nofollow" href="https://www.wordfence.com/help/general-data-protection-regulation/">
												https://www.wordfence.com/help/&#8203;general-data-protection-regulation/
											</a>
										</p>
									</td>
								</tr>
							</tbody>
						</table>

						<h3 className="h3 font-Grota text-[20px] text-white">Cookies Dritter</h3>

						<p className="mb-4">
							Diese Cookies werden von Dritten gesetzt. Beim Aufruf von Dienste Dritter können die Drittanbieter Daten mithilfe von Cookies speichern und/oder abrufen, die nicht
							der Kontrolle von uns unterliegen
						</p>
						<table className="table-fixed border-collapse text-sm text-left overflow-x-scroll block">
							<tbody>
								<tr className="hidden sm:table-row">
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Name des Cookie</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Zweck</p>
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">Sitzungs-Cookie/Session Cookie von Google Analytics. (Tool zur Website-Analyse)</p>
									</td>
									<td className="align-top block sm:table-cell">
										<p className="mb-4">
											Welche Daten diese Cookies erfassen, finden Sie hier:{" "}
											<a className="underline" target="_blank" rel="nofollow" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
												https://developers.google.com/&#8203;analytics/devguides/&#8203;collection/analyticsjs/cookie-usage
											</a>
										</p>
									</td>
								</tr>
							</tbody>
						</table>

						<h3 className="h3 font-Grota text-[20px] text-white">Webanalyse durch Google Analytics</h3>

						<p className="mb-4">
							Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem
							Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung
							dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP -Anonymisierung auf dieser
							Webseite, wird Ihre IP -Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
							Europäischen Wirtschaftsraum zuvor gekürzt.
						</p>
						<p className="mb-4">
							Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird
							Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
							Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
							übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
							Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden
							nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP -Adresse) an
							Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link (
							<a className="underline" target="_blank" rel="nofollow" href="http://tools.google.com/dlpage/gaoptout?hl=de">
								http://tools.google.com/&#8203;dlpage/gaoptout?hl=de
							</a>
							) verfügbare Browser-Plugin herunterladen und installieren.
						</p>
						<p className="mb-4">
							Angesichts der Diskussion um den Einsatz von Analysetools mit vollständigen IP-Adressen möchten wir darauf hinweisen, dass diese Website Google Analytics mit der
							Erweiterung „_anonymizeIp()“ verwendet und daher IP-Adressen nur gekürzt weiterverarbeitet werden, um eine direkte Personenbeziehbarkeit auszuschließen.
						</p>
						<p className="mb-4">
							Weitere Informationen zur Verwendung Ihrer Daten durch google finden Sie unter{" "}
							<a className="underline" target="_blank" rel="nofollow" href="http://www.google.com/analytics/terms/de.html">
								http://www.google.com/&#8203;analytics/terms/de.html
							</a>{" "}
							und{" "}
							<a className="underline" target="_blank" rel="nofollow" href="http://www.google.com/intl/de/analytics/privacyoverview.html">
								http://www.google.com/&#8203;intl/de/analytics/&#8203;privacyoverview.html
							</a>
							.
						</p>
						<p className="mb-4">Google Analytics deaktivieren.</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Newsletter-Versand durch Cleverreach</h3>

						<p className="mb-4">
							Über unseren Newsletter informieren wir Sie z.B. über neue Produkte, Aktionen, Gewinnspiele und Neuigkeiten. Wir versenden Newsletter nur mit Ihrer Einwilligung.
							Hierbei erhalten wir automatisch „technische Informationen“, die es uns z.B. ermöglichen auszuwerten, wie oft der Newsletter geöffnet wurde und welche Inhalte wie
							häufig angeklickt wurden (z.B. Öffnungsrate und Klickrate). Die Analyse dieser Informationen ermöglicht uns ferner, unsere digitalen Angebote, z. B. unseres
							Newsletters zu optimieren und mit Ihnen möglichst individuell zu kommunizieren. Damit wollen wir erreichen, dass Sie nur solche Inhalte erhalten, die Sie auch
							wirklich interessieren.
						</p>
						<p className="mb-4">
							Zur Verwaltung und Versendung des Newsletters verwenden wir das Newsletter System des Unternehmens CleverReach GmbH &amp; Co. KG mit Sitz in Mühlenstr. 43 in 26180
							Rastede.
						</p>
						<p className="mb-4">
							Einzelheiten zur Datenanalyse durch CleverReach finden Sie hier:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="https://www.cleverreach.com/de/funktionen/reporting-und-tracking/">
								https://www.cleverreach.com/&#8203;de/funktionen/&#8203;reporting-und-tracking/
							</a>
							.
						</p>
						<p className="mb-4">
							Einzelheiten zum Datenschutz von CleverReach finden Sie hier:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="https://www.cleverreach.com/de/datenschutz/">
								https://www.cleverreach.com/&#8203;de/datenschutz/
							</a>
							.
						</p>
						<p className="mb-4">
							Sind Sie mit einer Analyse durch CleverReach nicht einverstanden, müssen Sie den Newsletter abbestellen. Schicken Sie hierfür eine formlose Mitteilung per Mail an uns
							oder melden Sie sich über den „Abmelden“-Link im Newsletter ab.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Facebook</h3>

						<p className="mb-4">
							Auf unserer Website sind vorwiegend Verweise (Links) auf das externe soziale Netzwerk Facebook implementiert. Sie erkennen die Verweise im Rahmen unseres Webauftritts
							z.B. anhand des Facebook Logos. Wir verwenden hierfür keine sogenannten „Social-Plugins“ von Facebook. Durch das Anklicken dieser Symbole werden Sie direkt zu den
							Internetseiten von Facebook weitergeleitet. Dieser Internetauftritt wird von der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA, (Facebook)
							betrieben.
						</p>
						<p className="mb-4">
							Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von Facebook:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="https://www.facebook.com/about/privacy">
								https://www.facebook.com/&#8203;about/privacy
							</a>
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Facebook Social Plugins</h3>

						<ol className="text-white list-decimal ml-6">
							<li>
								Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne
								des Art. 6 Abs. 1 lit. f. DSGVO) Social Plugins („Plugins“) des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand
								Canal Harbour, Dublin 2, Irland betrieben wird („Facebook“). Die Plugins können Interaktionselemente oder Inhalte (z.B. Videos, Grafiken oder Textbeiträge)
								darstellen und sind an einem der Facebook Logos erkennbar (weißes „f“ auf blauer Kachel, den Begriffen „Like“, „Gefällt mir“ oder einem „Daumen hoch“-Zeichen) oder
								sind mit dem Zusatz „Facebook Social Plugin“ gekennzeichnet. Die Liste und das Aussehen der Facebook Social Plugins kann hier eingesehen werden:{" "}
								<a className="underline" target="_blank" rel="nofollow" href="https://developers.facebook.com/docs/plugins/">
									https://developers.facebook.com/&#8203;docs/plugins/
								</a>
								.
							</li>
							<li>
								Facebook ist unter dem{" "}
								<a className="underline" target="_blank" rel="nofollow" href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">
									Privacy-Shield-Abkommen
								</a>{" "}
								zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten.
							</li>
							<li>
								Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin enthält, baut sein Gerät eine direkte Verbindung mit den Servern von Facebook
								auf. Der Inhalt des Plugins wird von Facebook direkt an das Gerät des Nutzers übermittelt und von diesem in das Onlineangebot eingebunden. Dabei können aus den
								verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses Plugins
								erhebt und informiert die Nutzer daher entsprechend unserem Kenntnisstand.
							</li>
							<li>
								Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die entsprechende Seite des Onlineangebotes aufgerufen hat. Ist der Nutzer bei
								Facebook eingeloggt, kann Facebook den Besuch seinem Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button betätigen oder
								einen Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät direkt an Facebook übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von
								Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine
								anonymisierte IP-Adresse gespeichert.
							</li>
							<li>
								Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und Einstellungsmöglichkeiten
								zum Schutz der Privatsphäre der Nutzer, können diese den Datenschutzhinweisen von Facebook entnehmen:{" "}
								<a className="underline" target="_blank" rel="nofollow" href="https://www.facebook.com/about/privacy/">
									https://www.facebook.com/&#8203;about/privacy/
								</a>
								.
							</li>
							<li>
								Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über dieses Onlineangebot Daten über ihn sammelt und mit seinen bei Facebook gespeicherten
								Mitgliedsdaten verknüpft, muss er sich vor der Nutzung unseres Onlineangebotes bei Facebook ausloggen und seine Cookies löschen. Weitere Einstellungen und
								Widersprüche zur Nutzung von Daten für Werbezwecke, sind innerhalb der Facebook-Profileinstellungen möglich: oder über die{" "}
								<a className="underline" target="_blank" rel="nofollow" href="http://www.aboutads.info/choices/">
									US-amerikanische Seite
								</a>{" "}
								oder die{" "}
								<a className="underline" target="_blank" rel="nofollow" href="http://www.youronlinechoices.com/">
									EU-Seite
								</a>
								. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.
							</li>
						</ol>

						<h3 className="h3 font-Grota text-[20px] text-white">Facebook-, Custom Audiences und Facebook-Marketing-Dienste</h3>

						<ol className="text-white list-decimal ml-6">
							<li>
								Innerhalb unseres Onlineangebotes wird aufgrund unserer berechtigten Interessen an Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes und zu
								diesen Zwecken das sog. „Facebook-Pixel“ des sozialen Netzwerkes Facebook, welches von der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA, bzw. falls Sie in
								der EU ansässig sind, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird („Facebook“), eingesetzt.
							</li>
							<li>
								Facebook ist unter dem{" "}
								<a className="underline" target="_blank" rel="nofollow" href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">
									Privacy-Shield-Abkommen
								</a>{" "}
								zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten.
							</li>
							<li>
								Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen (sog.
								„Facebook-Ads“) zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen,
								die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der
								besuchten Webseiten bestimmt werden) aufweisen, die wir an Facebook übermitteln (sog. „Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch
								sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir
								ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, in dem wir sehen ob Nutzer nachdem Klick auf eine
								Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden (sog. „Conversion“).
							</li>
							<li>
								Das Facebook-Pixel wird beim Aufruf unserer Webseiten unmittelbar durch Facebook eingebunden und kann auf Ihrem Gerät ein sog. Cookie, d.h. eine kleine Datei
								abspeichern. Wenn Sie sich anschließend bei Facebook einloggen oder im eingeloggten Zustand Facebook besuchen, wird der Besuch unseres Onlineangebotes in Ihrem
								Profil vermerkt. Die über Sie erhobenen Daten sind für uns anonym, bieten uns also keine Rückschlüsse auf die Identität der Nutzer. Allerdings werden die Daten von
								Facebook gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist und von Facebook sowie zu eigenen Marktforschungs- und
								Werbezwecken verwendet werden kann. Sofern wir Daten zu Abgleichzwecken an Facebook übermitteln sollten, werden diese lokal in auf dem Browser verschlüsselt und
								erst dann an Facebook über eine gesicherte https-Verbindung gesendet. Dies erfolgt alleine mit dem Zweck, einen Abgleich mit den gleichermaßen durch Facebook
								verschlüsselten Daten herzustellen.
							</li>
							<li>
								Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks Datenverwendungsrichtlinie. Dementsprechend generelle Hinweise zur Darstellung von
								Facebook-Ads, in der Datenverwendungsrichtlinie von Facebook:{" "}
								<a className="underline" target="_blank" rel="nofollow" href="https://www.facebook.com/policy.php">
									https://www.facebook.com/policy.php
								</a>
								. Spezielle Informationen und Details zum Facebook-Pixel und seiner Funktionsweise erhalten Sie im Hilfebereich von Facebook:{" "}
								<a className="underline" target="_blank" rel="nofollow" href="https://www.facebook.com/business/help/651294705016616">
									https://www.facebook.com/&#8203;business/help/651294705016616
								</a>
								.
							</li>
							<li>
								Sie können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur Darstellung von Facebook-Ads widersprechen. Um einzustellen, welche Arten von
								Werbeanzeigen Ihnen innerhalb von Facebook angezeigt werden, können Sie die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen
								nutzungsbasierter Werbung befolgen:{" "}
								<a className="underline" target="_blank" rel="nofollow" href="https://www.facebook.com/settings?tab=ads">
									https://www.facebook.com/&#8203;settings?tab=ads
								</a>
								. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.
							</li>
							<li>
								Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, ferner über die{" "}
								<a className="underline" target="_blank" rel="nofollow" href="http://optout.networkadvertising.org/">
									Deaktivierungsseite der Netzwerkwerbeinitiative
								</a>{" "}
								und zusätzlich die{" "}
								<a className="underline" target="_blank" rel="nofollow" href="http://www.aboutads.info/choices">
									US-amerikanische Webseite
								</a>{" "}
								oder die{" "}
								<a className="underline" target="_blank" rel="nofollow" href="http://www.youronlinechoices.com/uk/your-ad-choices/">
									europäische Webseite
								</a>{" "}
								widersprechen.
							</li>
						</ol>

						<h3 className="h3 font-Grota text-[20px] text-white">LinkedIn</h3>

						<p className="mb-4">
							Auf unserer Website sind vorwiegend Verweise (Links) auf das externe soziale Netzwerk LinkedIn implementiert. Sie erkennen die Verweise im Rahmen unseres Webauftritts
							z.B. anhand des LinkedIn-Logos. Wir verwenden hierfür keine sogenannten „Social-Plugins“ von LinkedIn. Durch das Anklicken dieser Symbole werden Sie direkt zu den
							Internetseiten von LinkedIn weitergeleitet. Dieser Internetauftritt wird von der LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland (LinkedIn)
							betrieben.
						</p>
						<p className="mb-4">
							Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von LinkedIn:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="https://www.linkedin.com/legal/privacy-policy">
								https://www.linkedin.com/&#8203;legal/privacy-policy
							</a>
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">YouTube</h3>

						<p className="mb-4">
							Auf unserer Website sind Verweise (Links) auf der von Google betriebenen Seite YouTube implementiert. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San
							Bruno, CA 94066, USA. Wir verwenden hierfür keine so-genannten „Social-Plugins“ von YouTube. Durch das Anklicken dieser Symbole werden Sie direkt zu den
							Internetseiten von YouTube weitergeleitet.
						</p>
						<p className="mb-4">
							Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="https://policies.google.com/technologies/product-privacy?hl=de&gl=de">
								https://policies.google.com/technologies/product-privacy?hl=de&amp;gl=de
							</a>
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Pinterest</h3>

						<p className="mb-4">
							Auf unserer Website sind Verweise (Links) auf das soziale Netzwerk Pinterest, welches von der Pinterest, Inc. – ansässig 808 Brannan St, San Francisco, CA 94103, USA
							– betrieben wird, implementiert. Wir verwenden hierfür keine sogenannten „Social-Plugins“ von Pinterest. Durch das Anklicken dieser Symbole werden Sie direkt zu den
							Internetseiten von Pinterest weitergeleitet.
						</p>
						<p className="mb-4">
							Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von LinkedIn:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="https://policy.pinterest.com/de/privacy-policy">
								https://policy.pinterest.com/de/privacy-policy
							</a>
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Instagram</h3>

						<p className="mb-4">
							Auf unserer Website sind Verweise (Links) auf den Dienst Instagram eingebunden, der durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA betrieben
							wird. Wir verwenden hierfür keine sogenannten „Social-Plugins“ von Instagram. Durch das Anklicken dieser Symbole werden Sie direkt zu den Internetseiten von Instagram
							weitergeleitet.
						</p>
						<p className="mb-4">
							Um Ihre Privatsphäre schützen zu können, entnehmen Sie weitere Details zur Datenerhebung beziehungsweise Verarbeitung und Nutzung Ihrer Daten sowie Ihre rechtlichen
							Möglichkeiten und Einstellungsoptionen bitte den Datenschutzhinweisen von Instagram unter:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="http://instagram.com/about/legal/privacy/">
								http://instagram.com/about/legal/privacy/
							</a>
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Google Web Fonts</h3>

						<p className="mb-4">
							Google Web Fonts von Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). Google Web Fonts ermöglicht es uns Ihnen die von uns gewünschte
							Darstellung dieser Webseite zu präsentieren, unabhängig davon, welche Schriften bei Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts
							von einem Google Server in den USA und der damit verbundenen Weitergabe Ihrer Daten an Google. Neben der IP-Adresse werden auch die von Ihnen bei uns besuchten Seiten
							übermittelt.
						</p>
						<p className="mb-4">
							Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von Google:{" "}
							<a className="underline" target="_blank" rel="nofollow" href="https://policies.google.com/privacy">
								https://policies.google.com/privacy
							</a>
							.
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Google Maps</h3>

						<p className="mb-4">
							Auf unserer Website verwenden wir Google Maps (API) von Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). Google Maps ist ein Webdienst
							zur Darstellung von interaktiven (Land-)Karten, um geographische Informationen visuell darzustellen. Über die Nutzung dieses Dienstes wird Ihnen unser Standort
							angezeigt und eine etwaige Anfahrt erleichtert.
						</p>
						<p className="mb-4">
							Bereits beim Aufrufen derjenigen Unterseiten, in die die Karte von Google Maps eingebunden ist, werden Informationen über Ihre Nutzung unserer Website (wie z.B. Ihre
							IP-Adresse) an Server von Google in den USA übertragen und dort gespeichert. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie
							eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit
							Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten (selbst für nicht eingeloggte Nutzer) als
							Nutzungsprofile und wertet diese aus. Eine solche Auswertung erfolgt insbesondere gemäß Art. 6 Abs. 1 lit.f DSGVO auf Basis der berechtigten Interessen von Google an
							der Einblendung personalisierter Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
							dieser Nutzerprofile zu.
						</p>
						<p className="mb-4">
							Google LLC mit Sitz in den USA ist für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden
							Datenschutzniveaus gewährleistet.
						</p>
						<p className="mb-4">
							Wenn Sie mit der künftigen Übermittlung Ihrer Daten an Google im Rahmen der Nutzung von Google Maps nicht einverstanden sind, besteht auch die Möglichkeit, den
							Webdienst von Google Maps vollständig zu deaktivieren, indem Sie die Anwendung JavaScript in Ihrem Browser ausschalten. Google Maps und damit auch die Kartenanzeige
							auf dieser Internetseite kann dann nicht genutzt werden.
						</p>
						<p className="mb-4">
							Die Nutzungsbedingungen von Google können Sie unter{" "}
							<a className="underline" target="_blank" rel="nofollow" href="http://www.google.de/intl/de/policies/terms/regional.html">
								http://www.google.de/&#8203;intl/de/policies/terms/&#8203;regional.html
							</a>{" "}
							einsehen, die zusätzlichen Nutzungsbedingungen für Google Maps finden Sie unter{" "}
							<a className="underline" target="_blank" rel="nofollow" href="https://www.google.com/intl/de_US/help/terms_maps/">
								https://www.google.com/&#8203;intl/de_US/help/terms_maps/
							</a>
						</p>
						<p className="mb-4">
							Ausführliche Informationen zum Datenschutz im Zusammenhang mit der Verwendung von Google Maps finden Sie auf der Internetseite von Google („Google Privacy Policy“):{" "}
							<a className="underline" target="_blank" rel="nofollow" href="http://www.google.de/intl/de/policies/privacy/">
								http://www.google.de/&#8203;intl/de/policies/privacy/
							</a>
						</p>

						<h3 className="h3 font-Grota text-[20px] text-white">Wie lange speichern wir Ihre Daten?</h3>

						<p className="mb-4">
							Wir speichern Ihre personenbezogenen Daten, die Sie uns über unsere Websites übermitteln, in unseren Datenbanken. Die Speicherung erfolgt dabei solange, wie dies für
							den Zweck, für den Sie uns Ihre Daten übermittelt haben oder für die Einhaltung gesetzlicher Regelungen (z.B. gesetzliche Aufbewahrungspflichten) erforderlich ist.
							Beispielsweise bedeutet dies, dass die Speicherung solange erfolgt, bis wir eine Frage von Ihnen beantwortet haben oder ein Gewinnspiel beendet ist. Sofern Sie uns
							Ihr Einverständnis für die Speicherung Ihrer personenbezogenen Daten zu Marketingzwecken gegeben haben, bedeutet dies, dass Ihre Daten für die Dauer gespeichert
							werden, über die wir Marketingmaßnahmen ergreifen oder bis Sie Ihr Einverständnis widerrufen. Ihre gegebenenfalls erteilte Einwilligung in den Erhalt von Werbung
							können Sie jederzeit mit Wirkung für die Zukunft widerrufen.
						</p>
						<p className="mb-4">Stand: 01.07.2020</p>
						<p className="mb-4">&nbsp;</p>
					</div>

					{/* <p className=" text-white font-bold">Cookies</p>
						<table className="table-fixed border-collapse text-sm text-left overflow-x-scroll block">
							<thead>
								<tr>
									<th className="font-bold align-top">Cookie </th>
									<th className="font-bold align-top">Kategorie </th>
									<th className="font-bold align-top">Verarbeitung durch </th>
									<th className="font-bold align-top">Zweck </th>
									<th className="font-bold align-top">Speicherdauer </th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> _pk_id
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span> Tracking (nicht notwendige Cookies)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik (s. 2.2)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik Tracking – hinterlegt eine seitenspezifische ID zur Zuordnung des Datensatzes
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> _pk_ses
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span>
										<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik (s. 2.2)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik Tracking – hinterlegt eine ID für die aktuelle Browser-Session des Nutzers zur Zuordnung
										des Datensatzes
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 30 Minuten
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> stg_last_interaction
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span>
										<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik (s. 2.2)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik Tracking –speichert den Moment der letzten Interaktion des Nutzers
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> stg_returning_visitor
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span>
										<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik (s. 2.2)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik Tracking – speichert, ob es sich um einen wiederkehrenden Nutzer handelt
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> stg_traffic_source_priority
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span>
										<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik (s. 2.2)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik Tracking – speichert, über welche Quelle (Kampagne, Suchmaschine, direkte
										Eingabe) der Nutzer auf die Website kam
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 30 Minuten
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> stg_optout
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span>
										<span className=" hidden sm:inline-block"> Tracking (nicht notwendige Cookies) </span>
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Piwik (s. 2.2)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Piwik Tracking – speichert, dass nicht getrackt wird
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> hl
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span> Sprache (notwendiges Cookie)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Storck
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Lokale Settings – speichert Sprache und Domain der jeweiligen Seite (z. B.:
										„de_CH“ oder „en_COM“)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 12 Monate
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> hideCookieNotification
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span> Cookie-Benachrichtigung (notwendiges Cookie)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Storck
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Cookie Notification – wird gesetzt, damit die Cookie Notification nicht bei jedem
										Website-Besuch erneut angezeigt wird
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> 24 Stunden
									</td>
								</tr>
								<tr>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Cookie </span> hideLocationNotification
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Kategorie </span> Länderauswahl (notwendiges Cookie)
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Zweck </span> Storck
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Verarbeitung durch </span> Location Notification – wird gesetzt, damit der Country Selector nicht bei jedem
										Besuch der storck.com erneut angezeigt wird
									</td>
									<td className="align-top block sm:table-cell">
										<span className=" hidden sm:inline-block font-bold">Speicherdauer </span> Nur während der Browser-Sitzung (sog. Session Cookie).
									</td>
								</tr>
							</tbody>
						</table> */}
				</div>
			</div>

			<div className="text-center py-5">
				<button
					onClick={() => {
						navigate(-1)
					}}
					className="btn-invert mt-8"
				>
					Zurück
				</button>
			</div>
		</div>
	)
}
export default Privacy
