import { useAutoAnimate } from "@formkit/auto-animate/react"
import axios from "axios"
import { useContext, useState } from "react"
import Dropzone from "react-dropzone"
import { ERRORTYPES } from "../../config"
import { getEnvConfig } from "../../env"
import convertPDF2JPG from "../convertPDF/PDF2JPG"
import Alert from "../elements/Alert"
import { Context } from "../../store/store"

const ReceiptUpload = () => {
	const { dispatch } = useContext(Context)
	const env = getEnvConfig()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<ERRORTYPES | null>()
	const [acceptedFile, setAcceptedFile] = useState<any>(null)

	const [receiptUploadRef] = useAutoAnimate<HTMLDivElement>()

	function onDrop(dropedFiles: any) {
		setLoading(true)
		setError(null)
		setAcceptedFile(null)
		if (dropedFiles.length > 0) {
			if (dropedFiles[0].size > 8000000) {
				// 8MB
				setError(ERRORTYPES.FILESIZE)
				setLoading(false)
			} else {
				setError(null)

				handleFileType(dropedFiles[0])
				setLoading(true)
			}
		} else {
			setError(ERRORTYPES.FILESIZE)
			setLoading(false)
		}
	}

	function handleFileTypeError(error: any) {
		try {
			if (error.response.data.errors.errors[0] === "Participation with same receipt already exists.") {
				setError(ERRORTYPES.DOUBBLED)
			} else if (error.response.data.errors.errors[0] === "Reached maximum attempts with uploaded receipt.") {
				setError(ERRORTYPES.MAXTRIES)
			} else {
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
			}
		} catch (error) {
			setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
		}

		setLoading(false)
		setAcceptedFile(null)
	}

	function handleFileType(file) {
		setLoading(true)
		if (file.type !== "application/pdf") {
			uploadReceipt(file)
		} else {
			/* ✨ PDF magic ✨ */
			convertPDF2JPG(file)
				.then(file => {
					uploadReceipt(file)
				})
				.catch(error => {
					console.log("Error converting PDF to B64", error)
					setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
					setLoading(false)
					setAcceptedFile(file)
				})
		}
	}

	async function uploadReceipt(file: any) {
		const env = getEnvConfig()
		const formdata = new FormData()
		formdata.append("file", file)

		axios
			.post(env.apibase + "uploadReceipt.php?cv=" + Date.now(), formdata, {
				headers: {
					"content-type": "multipart/form-data",
					Accept: "application/json",
				},
			})
			.then(res => {
				console.log(res)
				if (res.data.enqueued) {
					checkOCRStatus(res.data.temporaryReceiptDataId)
				} else {
					handleFileTypeError(new Error("File submitted but something's wrong 🤔: " + JSON.stringify(res)))
				}
			})
			.catch(error => {
				handleFileTypeError(error)
				setAcceptedFile(null)
			})
	}

	async function checkOCRStatus(reference: string, maxTries: number = 10) {
		axios
			.get(env.apibase + "checkReceiptUploadStatus.php?reference=" + reference + "&cv=" + Date.now())
			.then(res => {
				if (!!res.data.classificationPassed) {
					// Process succeeded

					if (res.data.classificationProcessed) {
						dispatch({
							type: "SET_RECEIPT_REFERENCE",
							receiptReference: reference,
							receiptFileName: reference,
						})

						setAcceptedFile(reference)
					} else {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						setAcceptedFile(null)
					}

					setLoading(false)
				} else {
					maxTries--
					if (maxTries === 0) {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						setLoading(false)

						console.log(new Error("Too many attempts!"))
						setAcceptedFile(null)
					} else {
						setTimeout(() => checkOCRStatus(reference, maxTries), 1000)
					}
				}
			})
			.catch(error => {
				console.log(error)
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
				setAcceptedFile(null)
			})
	}

	return (
		<Dropzone onDrop={onDrop} multiple={false} accept="image/jpeg,image/png,application/pdf">
			{({ getRootProps, getInputProps }) => (
				<section ref={receiptUploadRef}>
					<div className="h3 pb-4 sm:text-[28px] leading-[24px] sm:leading-[34px]">Kassenbon hochladen</div>

					<div {...getRootProps()}>
						<input type="file" accept="image/jpeg,image/png,application/pdf" {...getInputProps()} />

						<div className="text-center md:min-h-[90px]  mx-auto">
							<p className="font-Quicksand  text-brown text-[15px] sm:text-[20px] leading-tight pb-4 font-bold">
								Achte darauf, dass der Kaufbeleg vollständig ist. Datum, Einkaufsstätte, Aktionsprodukt sowie Preis müssen gut lesbar sein.
							</p>
						</div>
						<p className="font-Quicksand  text-brown text-[15px] sm:text-[20px] leading-[25px] font-bold">Möglich sind JPG-, PNG- oder PDF-Dateien bis max. 8 MB.</p>
						<div className="flex items-center justify-center text-center min-h-[120px]">
							{acceptedFile && (
								<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="67.453" height="57.543" viewBox="0 0 67.453 57.543">
									<defs>
										<clipPath id="clip-path">
											<rect id="Rechteck_65" data-name="Rechteck 65" width="67.453" height="57.543" fill="#25b24c" />
										</clipPath>
									</defs>
									<g id="Gruppe_586" data-name="Gruppe 586" transform="translate(-153.773 -2023.729)">
										<g id="Ellipse_6" data-name="Ellipse 6" transform="translate(163.398 2025)" fill="#fef6e9" stroke="#25b24c" strokeWidth={2}>
											<circle cx="26.5" cy="26.5" r="26.5" stroke="none" />
											<circle cx="26.5" cy="26.5" r="25.5" fill="none" />
										</g>
										<g id="Gruppe_578" data-name="Gruppe 578" transform="translate(153.773 2023.729)">
											<g id="Gruppe_577" data-name="Gruppe 577" clipPath="url(#clip-path)">
												<path
													id="Pfad_1025"
													data-name="Pfad 1025"
													d="M25.36,59.565h-.073a2.365,2.365,0,0,1-.725-.134c-3.225-1.134-5.451-3.006-8.205-5.855-1.7-1.758-3.267-3.521-4.658-5.241l-.009-.011a117,117,0,0,1-8.62-11.264c-.87-1.281-.744-2.918.375-4.865l.009-.016a9.276,9.276,0,0,1,1.227-1.822l.308-.348-.021-.033c1.345-1.767,3.355-4,5.693-4a3.675,3.675,0,0,1,.87.106,4.247,4.247,0,0,1,2.381,1.654l.02.028.022.025c1.113,1.256,2.186,2.608,3.223,3.916a67.579,67.579,0,0,0,4.682,5.488,4.958,4.958,0,0,0,.478.456,2.8,2.8,0,0,0,1.718.792c.723,0,1.462-.42,2.725-1.55,3.133-2.8,6.271-5.96,9.306-9.012,2.294-2.307,4.665-4.691,7.035-6.927C46.1,18.14,54.238,11.034,57.453,8.342A66.1,66.1,0,0,1,65,2.632,1.034,1.034,0,0,1,65.55,2.5a3.793,3.793,0,0,1,2.358,1.114,6.442,6.442,0,0,1,1.154,1.453,7.252,7.252,0,0,1,.4,1.9,1.6,1.6,0,0,1-.391,1.273C65.791,11.917,60.13,18.4,55.136,24.119l-.011.013c-2.551,2.921-4.96,5.681-6.674,7.633-4.322,4.921-7.41,9.137-10.985,14.019C35.7,48.2,33.87,50.691,31.7,53.54l-.017.023a28.211,28.211,0,0,1-4.33,5.1,4.666,4.666,0,0,1-.911.634,2.32,2.32,0,0,1-1.083.265"
													transform="translate(-2.261 -2.261)"
													fill="#25b24c"
												/>
												<path
													id="Pfad_1026"
													data-name="Pfad 1026"
													d="M25.36,59.565h-.073a2.365,2.365,0,0,1-.725-.134c-3.225-1.134-5.451-3.006-8.205-5.855-1.7-1.758-3.267-3.521-4.658-5.241l-.009-.011a117,117,0,0,1-8.62-11.264c-.87-1.281-.744-2.918.375-4.865l.009-.016a9.276,9.276,0,0,1,1.227-1.822l.308-.348-.021-.033c1.345-1.767,3.355-4,5.693-4a3.675,3.675,0,0,1,.87.106,4.247,4.247,0,0,1,2.381,1.654l.02.028.022.025c1.113,1.256,2.186,2.608,3.223,3.916a67.579,67.579,0,0,0,4.682,5.488,4.958,4.958,0,0,0,.478.456,2.8,2.8,0,0,0,1.718.792c.723,0,1.462-.42,2.725-1.55,3.133-2.8,6.271-5.96,9.306-9.012,2.294-2.307,4.665-4.691,7.035-6.927C46.1,18.14,54.238,11.034,57.453,8.342A66.1,66.1,0,0,1,65,2.632,1.034,1.034,0,0,1,65.55,2.5a3.793,3.793,0,0,1,2.358,1.114,6.442,6.442,0,0,1,1.154,1.453,7.252,7.252,0,0,1,.4,1.9,1.6,1.6,0,0,1-.391,1.273C65.791,11.917,60.13,18.4,55.136,24.119l-.011.013c-2.551,2.921-4.96,5.681-6.674,7.633-4.322,4.921-7.41,9.137-10.985,14.019C35.7,48.2,33.87,50.691,31.7,53.54l-.017.023a28.211,28.211,0,0,1-4.33,5.1,4.666,4.666,0,0,1-.911.634A2.32,2.32,0,0,1,25.36,59.565Z"
													transform="translate(-2.261 -2.261)"
													fill="#25b24c"
												/>
											</g>
										</g>
									</g>
								</svg>
							)}

							{error && (
								<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="58.914" height="63.333" viewBox="0 0 58.914 63.333">
									<defs>
										<clipPath id="clip-path">
											<rect id="Rechteck_66" data-name="Rechteck 66" width="57.626" height="63.333" fill="#e5132d" />
										</clipPath>
									</defs>
									<g id="Gruppe_583" data-name="Gruppe 583" transform="translate(-1216.899 -2758.464)">
										<g id="Ellipse_6" data-name="Ellipse 6" transform="translate(1216.899 2766)" fill="#fef6e9" stroke="#e50c2d" strokeWidth={2}>
											<circle cx="26.5" cy="26.5" r="26.5" stroke="none" />
											<circle cx="26.5" cy="26.5" r="25.5" fill="none" />
										</g>
										<g id="Gruppe_580" data-name="Gruppe 580" transform="translate(1218.188 2758.464)">
											<g id="Gruppe_579" data-name="Gruppe 579" clipPath="url(#clip-path)">
												<path
													id="Pfad_1027"
													data-name="Pfad 1027"
													d="M9.328,63.333c-2.985,0-6.687-3.94-7.751-5.147l-.021-.023a5.081,5.081,0,0,1-.416-.489,5.968,5.968,0,0,1-.113-6.856c3.83-5.231,7.841-10.4,11.92-15.357l.345-.419-.348-.417C9.582,30.6,6.253,26.418,3.051,22.2l-.414-.546-.056.047a11.315,11.315,0,0,1-1.518-2.132c-.969-1.817-.965-3.356.011-4.575,3.605-4.5,6.239-5.172,7.487-5.172a2.717,2.717,0,0,1,.725.089l.018.005.018,0a4.39,4.39,0,0,1,1.8.873l.124.111a4.317,4.317,0,0,1,.525.556c2.65,3.351,5.266,6.327,8.035,9.477l.009.011c.73.831,1.485,1.69,2.24,2.555l.482.552.495-.54A128.057,128.057,0,0,1,50.042.593,4.049,4.049,0,0,1,52.159,0a4.339,4.339,0,0,1,3.767,2.185c.363.639.731,1.273,1.094,1.885a4.413,4.413,0,0,1-.975,5.612c-4.643,3.833-9,7.773-12.952,11.712-4.175,4.161-8.165,8.282-11.106,12.945L31.7,34.8l.4.37c4.307,3.943,8.761,8.021,13.065,12.223a4.759,4.759,0,0,1,.339,6.33c-1.129,1.363-2.263,2.754-3.371,4.134A4.128,4.128,0,0,1,38.9,59.418a4,4,0,0,1-2.572-.923c-4.332-3.57-8.648-7.316-12.827-11.134l-.54-.493-.431.591c-1.041,1.427-2.048,2.772-3.021,4.074-2.254,3.012-4.382,5.855-6.6,9.26a4.21,4.21,0,0,1-.989,1.069l-.075.057-.056.076a2.934,2.934,0,0,1-2.452,1.34"
													transform="translate(0)"
													fill="#e5132d"
												/>
											</g>
										</g>
									</g>
								</svg>
							)}

							{loading && (
								<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="68.48" height="61.275" viewBox="0 0 68.48 61.275" className="animate-spin">
									<defs>
										<clipPath id="clip-path">
											<rect id="Rechteck_63" data-name="Rechteck 63" width="68.48" height="61.275" transform="translate(0 0)" fill="#4b2428" />
										</clipPath>
									</defs>
									<g id="Gruppe_581" data-name="Gruppe 581" transform="translate(0 -0.002)">
										<g id="Gruppe_573" data-name="Gruppe 573" transform="translate(0 0.002)" clipPath="url(#clip-path)">
											<path
												id="Pfad_1022"
												data-name="Pfad 1022"
												d="M52.274,1142.251a1.05,1.05,0,0,0-1.405.262,20.827,20.827,0,0,1-16.824,8.4,20.581,20.581,0,0,1-20.434-16.884,1.051,1.051,0,0,1,1.037-1.224h5.141a1.052,1.052,0,0,0,.867-1.647l-9.367-13.626a1.051,1.051,0,0,0-1.733,0L.187,1131.16a1.052,1.052,0,0,0,.867,1.647H5.681a1.05,1.05,0,0,1,1.044.931,25.938,25.938,0,0,0,1.994,7.437,26.633,26.633,0,0,0,5.889,8.5,27.444,27.444,0,0,0,8.736,5.732,28.218,28.218,0,0,0,24.027-1.246,27.45,27.45,0,0,0,9.1-7.931,1.052,1.052,0,0,0-.286-1.511Z"
												transform="translate(0 -1096.239)"
												fill="#4b2428"
											/>
											<path
												id="Pfad_1023"
												data-name="Pfad 1023"
												d="M637.334,15.263A1.05,1.05,0,0,0,638.739,15a20.826,20.826,0,0,1,16.824-8.4A20.581,20.581,0,0,1,676,23.484a1.051,1.051,0,0,1-1.037,1.224h-5.141a1.052,1.052,0,0,0-.867,1.647l8.562,12.454a2.03,2.03,0,0,0,3.345,0l8.561-12.454a1.052,1.052,0,0,0-.867-1.647h-4.628a1.05,1.05,0,0,1-1.044-.931,25.942,25.942,0,0,0-1.994-7.437A26.634,26.634,0,0,0,675,7.837,27.446,27.446,0,0,0,666.263,2.1a28.217,28.217,0,0,0-24.027,1.247,27.45,27.45,0,0,0-9.1,7.931,1.053,1.053,0,0,0,.286,1.511Z"
												transform="translate(-621.127 -0.001)"
												fill="#4b2428"
											/>
										</g>
									</g>
								</svg>
							)}
						</div>
						<button type="button" className="btn mb-6" disabled={loading ? true : undefined}>
							hochladen
						</button>
					</div>

					{error && <Alert className={"text-left mt-3"}>{error}</Alert>}
				</section>
			)}
		</Dropzone>
	)
}

export default ReceiptUpload
