import axios from "axios"
import { getEnvConfig } from "../../env"

const backendValidationMapping = {
	salutation: "invalid_salutation",
	firstName: "invalid_firstname",
	lastName: "invalid_surname",
	email: "invalid_email",
	price: "invalid_purchaseAmount",
	totalAmount: "invalid_purchaseTotal",
	shop: "invalid_merchant",
	accountHolder: "invalid_bankName",
	iban: "invalid_iban",
	bic: "invalid_bic",
	over18OptIn: "invalid_age",
	product: "invalid_package",
	purchaseDate: "invalid_purchaseDate",
}

export function placeOrder(data, setValidation, successCallback, errCallback) {
	const env = getEnvConfig()

	const formdata = new FormData()

	// converting object to formdata
	for (var key in data) {
		formdata.append(key, data[key])
	}

	axios
		.post(env.apibase + "order.php?cv=" + Date.now(), formdata, {
			headers: {
				"content-type": "multipart/form-data",
				Accept: "application/json",
			},
		})
		.then(res => {
			console.log(res)
			if (res.status === 200) {
				if (res.data.externalIdentifier) {
					successCallback(res.data.externalIdentifier)
				} else {
					parseOrderErrors(res.data.errors, setValidation)
					errCallback()
				}
			} else {
				errCallback()
			}
		})
		.catch(error => {
			console.log(error, error.response)
			if (error.response && error.response.status >= 400 && error.response.status < 500) {
				// for 40X cases
				parseOrderErrors(error.response.data.errors, setValidation)
			}

			// Throttling of participations
			try {
				if (error.response.data === "Captcha code information missing.") {
					errCallback(false, true)
				} else if (error.response.data === "Captcha invalid.") {
					errCallback(false, true)
				} else if (error.response.data.errors.errors[0] === "Too many attempts. You have been blocked for a few minutes.") {
					errCallback(true, false)
				} else {
					errCallback()
				}
			} catch (e) {
				errCallback()
			}
		})
}

function parseOrderErrors(errors, setValidation) {
	if (errors) {
		for (const key in errors.children) {
			if (errors.children[key].errors && backendValidationMapping[key]) {
				setValidation({
					[backendValidationMapping[key] + "_errMsg"]: errors.children[key].errors.join(" "),
					[backendValidationMapping[key]]: true,
					showValidation: true,
				})
			}
		}
	}
}
