interface PreFillFormProps {
	formElementChanged: Function
}
const PreFillForm: React.FC<PreFillFormProps> = ({ formElementChanged }) => {
	return (
		<>
			{process.env.NODE_ENV === "development" && (
				<span
					className="btn cursor-pointer"
					onClick={() => {
						formElementChanged({ target: { value: "1" } }, "salutation")
						formElementChanged({ target: { value: "firstname" } }, "firstname")
						formElementChanged({ target: { value: "lastname" } }, "surname")
						formElementChanged({ target: { value: "asdf@fake.de" } }, "email")
						formElementChanged({ target: { value: "1" } }, "purchaseAmount")
						formElementChanged({ target: { value: "12" } }, "purchaseTotal")
						formElementChanged({ target: { value: "2024-02-18" } }, "purchaseDate")
						formElementChanged({ target: { value: "a" } }, "merchant")
						formElementChanged({ target: { value: "Mr Right" } }, "bankName")
						formElementChanged({ target: { value: "DE75512108001245126199" } }, "iban")
					}}
				>
					+++ fill with testdata ++++
				</span>
			)}
		</>
	)
}

export default PreFillForm
