import { useContext } from "react"

import { PACKAGES } from "../../config"
import { Context } from "../../store/store"

interface SelectPackageProps {
	formElementChanged: Function
}
const SelectPackage: React.FC<SelectPackageProps> = ({ formElementChanged }) => {
	const { globalState } = useContext(Context)
	return (
		<div className="pb-6 sm:pb-20 pt-20">
			<div className="sm:px-12">
				<div className="grid grid-cols-2 sm:grid-cols-4  gap-6 max-w-[1120px] px-4 mx-auto">
					{Object.keys(PACKAGES).map((item, index) => {
						const checkboxPos = [
							"w-[27%] absolute right-0 top-[45%] sm:top-[65%]",
							"w-[27%] absolute left-0 top-[35%] sm:left-auto sm:right-0 sm:top-[65%]",
							"w-[27%] absolute right-0 top-[15%] sm:right-0 sm:top-[65%]",
							"w-[27%] absolute left-0 top-[55%] sm:left-auto sm:right-0 sm:top-[65%]",
						]
						const isSelected = item === globalState.package ? true : false
						return (
							<div key={"package" + PACKAGES[item]} onClick={() => formElementChanged({ target: { value: item } }, "package")} className=" cursor-pointer relative">
								<img src={process.env.PUBLIC_URL + "/images/packs/" + item + ".png"} alt={item} loading="lazy" className=" w-full max-w-[265px] mx-auto  scale-105" />
								{isSelected ? (
									<img src={process.env.PUBLIC_URL + "/images/icon-packageSelected.svg"} key="not selectted" alt={"selected"} loading="lazy" className={checkboxPos[index]} />
								) : (
									<img src={process.env.PUBLIC_URL + "/images/icon-packageSelect.svg"} key="selectted" alt={"not selected"} loading="lazy" className={checkboxPos[index]} />
								)}
								<div className="relative pl-6">
									<div className="font-Quicksand text-[18px] sm:text-[19px]  leading-tight  text-yellow pl-2 text-center max-w-[100px] mx-auto relative right-6 -mt-3 md:-mt-7">
										{PACKAGES[item]}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default SelectPackage
