import { useEffect } from "react"
import Header from "../components/Header"
import { Link } from "react-router-dom"

function PromoSoon() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green relative">
			<div className="absolute left-[60%] top-[0%] w-[40%] h-[70%] bg-[length:140%_140%] bg-left-bottom bg-[url('/src/bgimages/kreis.svg')] bg-no-repeat opacity-50"></div>
			<div className="absolute left-[0%] top-[20%] w-[30%] h-[70%] bg-[length:auto_100%] bg-right bg-[url('/src/bgimages/kreis.svg')] bg-no-repeat opacity-50"></div>

			<div className="text-center relative pt-20 xl:pt-0 ">
				<Header addClass="text-white" />
				<div className=" max-w-[1640px]  mx-auto ">
					<h2 className="h2 text-white px-4 ">Die Probier-Rabatt-Aktion beginnt bald.</h2>

					<p className="text-white text-[25px]  sm:text-[40px] leading-normal pt-10 font-Quicksand px-4">
						Ab dem 02.01.2024 kannst du hier deinen 1 € Probier-Rabatt abchecken. <br />
						Weitere Informationen findest du in unseren{" "}
						<Link className="underline font-normal" to="/teilnahmebedingungen">
							Teilnahmebedingungen
						</Link>
						.
					</p>
					<a href="https://www.purmacherei.de/" target="_blank" rel="nofollow" className="btn-invert inline-block mt-20">
						zur PURmacherei
					</a>

					<img src={process.env.PUBLIC_URL + "/images/promosoon.png"} className=" w-full max-w-[1525px] mx-auto relative z-10 pt-20 sm:pt-40 " alt="" />
				</div>
			</div>
		</div>
	)
}
export default PromoSoon
