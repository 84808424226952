import axios from "axios"

const domains = {
	"vegansnacken-purmacherei.de": {
		apibase: "/",
		activatePromoSoon: true,
	},
	"www.vegansnacken-purmacherei.de": {
		apibase: "/",
		activatePromoSoon: true,
	},
	"prod.fe.purmacherei-haferhaps-probieren.campaign-loyalty.com": {
		apibase: "/",
		activatePromoSoon: true,
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "/inte/", activatePromoSoon: false }
}

export async function getPromoLimit(callback) {
	const env = getEnvConfig()
	await axios
		.get(env.apibase + "checkLimit.php?cv=" + Date.now())
		.then(res => {
			callback(res.data.limitReached)
		})
		.catch(error => {
			callback(false)
		})
}

// TODO: Recaptacha raus? Entscheidung steht noch aus
