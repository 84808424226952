import ReceiptUpload from "./ReceiptUpload"

const Receipt = () => {
	return (
		<>
			<div className="max-w-[1300px] mx-auto text-center relative z-10 px-4">
				<h2 className="h2">Jetzt mitmachen und gratis probieren!</h2>
				<p className="text-brown font-Grota text-[20px]  sm:text-[28px] leading-tight max-w-[632px] mx-auto pt-9 pb-16 sm:pb-[115px]">
					Lade jetzt ein Foto des Kassenbons hoch und klicke auf „Jetzt teilnehmen“.
				</p>

				<div className="mx-1 max-w-[1011px] sm:mx-auto relative z-10 bg-[url('/src/bgimages/uploadbg-mobile.svg')] sm:bg-[url('/src/bgimages/uploadbg.svg')]  bg-[length:100%_100%] bg-no-repeat pt-[73px] pb-[6%] pl-[6%] pr-[6%]  sm:pl-[16%] sm:pr-[12%] ">
					<img
						src={process.env.PUBLIC_URL + "/images/himbeer2.png"}
						className="w-[100px] sm:w-[235px] absolute -top-[40px] right-[40px]  sm:-top-[200px] sm:-right-[120px]"
						alt=""
					/>
					<img src={process.env.PUBLIC_URL + "/images/himbeer1.png"} className="w-[90px] sm:w-[205px] absolute -top-[10px] right-[0px] sm:-top-[40px] sm:-right-[170px]" alt="" />
					<img
						src={process.env.PUBLIC_URL + "/images/icon-upload.svg"}
						className=" w-full max-w-[109px] sm:max-w-[194px] absolute left-[5%] sm:left-[18%] -top-[40px] sm:-top-[85px] "
						alt=""
					/>
					<ReceiptUpload />
				</div>
			</div>
		</>
	)
}

export default Receipt
