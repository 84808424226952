import React, { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import Header from "../components/Header"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green">
			<Header addClass="text-white" />
			<>
				<div className="container max-w-[800px] mx-auto px-4  ">
					<h2 className="h2 text-white pt-12 ">Teilnahme&shy;bedingungen </h2>
					<h3 className="h3 text-white ">„HaferHAPS Probier-Rabatt-Aktion“ 2024</h3>
					<h3 className="h3 text-white py-4">1. Allgemeine Bedingungen</h3>
					<p className="mb-4 text-white">
						Die PURmacherei, Alfred-Ritter-Str. 25, 71111 Waldenbuch (im Folgenden „Veranstalter“ genannt) veranstaltet in der Zeit vom 02.01.2024 bis 30.09.2024 die Promotion
						„HaferHAPS Probier-Rabatt-Aktion“ in Deutschland. Die Abwicklung der Aktion erfolgt durch die Magellan GmbH, Oststraße 10, D-40211 Düsseldorf und die direct services
						Gütersloh GmbH, Reinhard-Mohn-Str. 300, 33333 Gütersloh.
					</p>
					<p className="mb-4 text-white">
						Im Aktionszeitraum können Teilnehmer mit einer gekauften HaferHAPS Aktionspackung teilnehmen und erhalten 1 Euro vom gezahlten Kaufpreis des Aktionsproduktes zurück.
						Nach dem 30.09.2024 ist eine Teilnahme nicht mehr möglich. Pro Bankkonto kann während des Aktionszeitraums ein Aktionsprodukt probiert werden. Jeder Kassenbon darf nur
						einmalig zur Teilnahme an der Aktion genutzt werden.
					</p>
					<h3 className="h3 text-white py-4">2. Teilnahmeberechtigte</h3>
					<p className="mb-4 text-white">
						Teilnehmen können nur natürliche Personen ab 18 Jahren, mit Konto im SEPA Raum (ein Land innerhalb der Europäischen Union sowie Island, Lichtenstein, Norwegen, Schweiz,
						Andorra, Monaco, San Marino, Vatikanstadt, Vereinigtes Königreich, Gibraltar, Isle of Man, Guernsey und Jersey). Der Betrag wird in Euro auf das vom Teilnehmer
						angegebene Konto überwiesen. Eventuelle Gebühren, die die kontoführende Bank des Teilnehmers für den Empfang des Geldes oder die Umrechnung von Euro in andere Währungen
						berechnet, hat der Kontoinhaber selbst zu tragen.
					</p>
					<p className="mb-4 text-white">
						Eine Teilnahme ist nur höchstpersönlich möglich und erfordert die wahrheitsgemäße und vollständige Angabe der persönlichen Daten. Die Teilnahme über Gewinnspielvereine,
						automatisierte Dienste und vor allem über einen professionellen Gewinnspielservice ist nicht möglich.
					</p>
					<h3 className="h3 text-white py-4">3. Ablauf </h3>
					<p className="mb-4 text-white">
						Für die Teilnahme an der „HaferHAPS Probier-Rabatt-Aktion“ ist der Kauf einer HaferHAPS Aktionspackung im Handel erforderlich. Onlineshops sind von der Aktion
						ausgenommen. An der Aktion nehmen folgende Produkte teil: HaferHAPS Himbeere 50g, HaferHAPS Kokos 50g, HaferHAPS Kakao 50g und HaferHAPS Mandel 50g.
					</p>
					<p className="mb-4 text-white">
						Um an der Aktion teilnehmen zu können, lädt der Teilnehmer auf der Aktionsseite{" "}
						<Link to="/" className="underline" target={"_blank"}>
							www.vegansnacken-purmacherei.de
						</Link>{" "}
						ein Foto hoch, auf dem der Kassenbon zu sehen sein müssen. Auf dem Kassenbon abgebildete personenbezogene Daten (z. B. Namen von Beschäftigten) sind nicht abzulichten
						oder zumindest vorab zu schwärzen oder in sonstiger Weise unkenntlich zu machen. Auf dem Kassenbon müssen die folgenden Daten erkennbar sein: Datum, Uhrzeit,
						Einkaufsstätte sowie das Aktionsprodukt inkl. Preis und die Bonsumme. Die Bilder des Kassenbons und der Aktionspackung sind als JPG, PNG oder PDF hochzuladen und dürfen
						die maximale Dateigröße von 8 MB nicht überschreiten. Anschließend wählt der Teilnehmer das gekaufte Aktionsprodukt aus, trägt den Kaufpreis des Produktes, das
						Kaufdatum und die Einkaufsstätte ein und ergänzt seine Kontaktdaten (E-Mail-Adresse, Vor- und Nachname), sowie die Kontoinformationen (Kontoinhaber, IBAN, bei
						ausländischen Konten zudem die BIC) in den jeweiligen Feldern auf{" "}
						<Link to="/" className="underline" rel="noreferrer" target={"_blank"}>
							www.vegansnacken-purmacherei.de
						</Link>
						. Nach dem Absenden der Teilnahmedaten erhält der Teilnehmer eine Bestätigungsmail. Der Veranstalter prüft im Anschluss die Einhaltung der Teilnahmevoraussetzungen.
						Falls diese nicht vorliegen, erhält der Teilnehmer eine Benachrichtigung. In diesem Fall kann der Teilnehmer innerhalb des Aktionszeitraumes mit einem neuen Kassenbon
						erneut an der Aktion teilnehmen.
					</p>
					<p className="mb-4 text-white">
						Für die vollständige und korrekte Angabe der Personen- und Bankdaten ist der Teilnehmer verantwortlich. Die Rückerstattung kann nur dann vorgenommen werden, wenn die
						angegebenen Daten korrekt sind. Die Rückerstattung in Höhe von 1 Euro erfolgt innerhalb von vier Wochen nach erfolgreicher Prüfung der Teilnahmevoraussetzungen. Eine
						Bestätigung der Überweisung per E-Mail wird nicht versendet.
					</p>
					<p className="mb-4 text-white">Jeder Kassenbon darf nur einmalig zur Teilnahme an der Aktion genutzt werden.</p>
					<p className="mb-4 text-white">Auszahlungsansprüche sind nicht auf Dritte übertragbar.</p>
					<h3 className="h3 text-white py-4">4. Datenschutz</h3>
					<p className="mb-4 text-white">
						Der Veranstalter nutzt die im Rahmen der Promotion verarbeiteten personenbezogenen Daten ausschließlich zum Zwecke der Durchführung und Abwicklung der Promotion. Diese
						sind im Anmeldeformular als Pflichtfelder gekennzeichnet. Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 b) DSGVO. Falls Sie diese Daten nicht
						bereitstellen, können Sie an der Aktion nicht teilnehmen.
					</p>
					<p className="mb-4 text-white">
						Die darüber hinaus erhobenen Daten sind freiwillig und erfolgen auf der rechtlichen Grundlage unseres berechtigten Interesses (Art. 6 Abs. 1 f) DSGVO). Die Anrede
						fragen wir ab, um Sie wunschgemäß ansprechen zu können.
					</p>
					<p className="mb-4 text-white">
						Zur Durchführung der Aktion setzt der Veranstalter als Dienstleister die direct services Gütersloh GmbH (Reinhard-Mohn-Straße 300, 33333 Gütersloh Germany) ein, mit der
						ein Auftragsverarbeitungsverhältnis besteht. Zur Auszahlung der Cashback-Beträge wird ein in Deutschland ansässiges Zahlungsinstitut eingesetzt.
					</p>
					<p className="mb-4 text-white">
						Die o.g. Daten werden gelöscht, sobald sie für die Erreichung der vorgenannten Zwecke nicht mehr erforderlich sind. Im Falle der Verarbeitung Ihrer IP-Adresse erfolgt
						die Löschung spätestens nach 3 Monaten, bei Missbrauchsfällen nach Abschluss des behördlichen oder gerichtlichen Verfahrens. Weitere Informationen zum Datenschutz
						findest du unter:{" "}
						<a href="https://www.purmacherei.de/policies/privacy-policy" className="underline" rel="noreferrer" target={"_blank"}>
							https://www.purmacherei.de/policies/privacy-policy
						</a>
						.
					</p>
					<h3 className="h3 text-white py-4">5. Haftung</h3>
					<p className="mb-4 text-white">
						Der Veranstalter haftet nach den gesetzlichen Bestimmungen für eigenes Verschulden und grobe Fahrlässigkeit, sowie das seiner gesetzlichen Vertreter, leitenden
						Angestellten oder sonstigen Erfüllungsgehilfen. Das Gleiche gilt für die Übernahme von Garantien oder sonstiger verschuldensunabhängiger Haftung sowie für Ansprüche
						nach dem Produkthaftungsgesetz oder bei schuldhafter Verletzung von Leben, Körper Gesundheit.
					</p>
					<p className="mb-4 text-white">
						Der Veranstalter haftet dem Grunde nach für die eigene, die seiner Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen einfache fahrlässige Verletzung
						wesentlicher Vertragspflichten, d.h. solcher Pflichten, auf deren Erfüllung der Teilnehmer regelmäßig vertrauen darf, um die Aktion ordnungsgemäß durchführen zu können;
						in diesem Fall ist die Haftung der Höhe nach auf den typischerweise entstehenden, vorhersehbaren Schaden begrenzt.
					</p>
					<p className="mb-4 text-white">Jede weitere Haftung des Veranstalters ist ausgeschlossen.</p>
					<h3 className="h3 text-white py-4">6. Sonstiges</h3>
					<p className="mb-4 text-white">
						Sollten einzelne Bestimmungen dieser Teilnahmebedingungen ungültig sein oder werden, so bleibt die Gültigkeit der übrigen Bestandteile dieser Teilnahmebedingungen
						hiervon unberührt.
					</p>
					<p className="mb-4 text-white">Es gilt das Recht der Bundesrepublik Deutschland. Der Rechtsweg ist ausgeschlossen.</p>
				</div>
			</>
			<div className="text-center pt-5 py-10">
				<button onClick={() => navigate(-1)} className="btn btn-invert mt-8">
					Zurück
				</button>
			</div>
		</div>
	)
}
export default Terms
