import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"

const Imprint: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green">
			<Header addClass="text-white" />
			<div className="container max-w-[800px] mx-auto px-4 ">
				<h2 className="h2 pt-12 text-white ">Impressum</h2>
				<div className="mt-12 text-white">
					<p>
						Hang zur Sonne GmbH
						<br />
						PURmacherei
						<br />
						Alfred-Ritter-Str. 25
						<br />
						71111 Waldenbuch
					</p>
					<p>
						Telefon: +49 176 – 432 586 24
						<br />
						Web:<span>&nbsp;</span>
						<a href="https://www.purmacherei.de/">www.purmacherei.de</a>
						<br />
						E-Mail:<span>&nbsp;</span>
						<a href="mailto:hello@purmacherei.de">hello@purmacherei.de</a>
					</p>
					<p>USt.Id.: DE 323661568</p>
					<p>
						Geschäftsführer: Tim Hoppe, Michael Lessmann, Moritz Ritter
						<br />
						Registergericht: Stuttgart
						<br />
						HRB-Nummer: 767787
					</p>
					<p>
						Inhaltlich verantwortlich gemäß § 5 TMG sind Tim Hoppe, Michael Lessmann, Moritz Ritter
						<br />
					</p>
					<p>Bankverbindung</p>
					<p>
						IBAN: DE62 6005 0101 0405 2207 08
						<br />
						BIC: SOLADEST600
						<br />
						Bank: Baden-Württembergische Bank
					</p>
					<p>
						Pflichtinformation zur Online-Beilegung verbraucherrechtlicher Streitigkeiten nach Art. 14 Abs. 1 Verordnung (EU) Nr. 524/2013:
						<br />
						Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) unter dem Link<span>&nbsp;</span>
						<a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>
						<span>&nbsp;</span>bereit. Die Plattform dient der Beilegung von Streitigkeiten zwischen Verbrauchern und Unternehmern, die sich aus dem Online-Verkauf von Waren oder
						der Online-Erbringung von Dienstleistungen ergeben.
					</p>
					<p>
						Technische Realisation dieser Website:
						<br />
						Halbstark GmbH
					</p>
					<p>
						Neckarstrasse 122
						<br />
						70190 Stuttgart
						<br />
						Tel.: <a href="tel:+491639861036">+49 163 986 10 36</a>
						<br />
						Web:<span>&nbsp;</span>
						<a href="https://www.banauten.com/">www.halbstark.de</a>
						<br />
						E-Mail:<span> </span>
						<a href="mailto:hello@banauten.com">info@halbstark.com</a>
					</p>
					<h3 className="h3 text-white pt-12 pb-6">Haftung für Inhalte</h3>
					<p>
						Die Inhalte unserer Internetseiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr
						übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs. 1 Telemediengesetz (TMG) für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
						§§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
						forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
						bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
						entsprechenden Rechtsverletzungen werden wir solche Inhalte umgehend entfernen.
					</p>
					<h3 className="h3 text-white pt-12 pb-6">Haftung für Links</h3>
					<p>
						Unser Angebot enthält Links zu Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für solche fremden Inhalte auch keine Gewähr
						übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
						der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
						der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir betreffende
						Links umgehend entfernen.
					</p>
					<h3 className="h3 text-white pt-12 pb-6">Urheber- und andere Schutzrechte</h3>

					<p>
						Sämtliche Inhalte unserer Internetseiten, einschließlich der Texte, Bilder, grafischen Darstellungen sowie Audio- und Videodateien, sind – soweit dies nicht
						ausdrücklich anders vermerkt ist – unser Eigentum. Die Vervielfältigung, Bearbeitung oder Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
						Urheberrechts bedürfen der schriftlichen Zustimmung seitens der Hang zur Sonne GmbH. Downloads und Kopien dieser Seiten sind nur für den privaten, nicht kommerziellen
						Gebrauch gestattet. Soweit die Inhalte auf diesen Seiten nicht vom Betreiber erstellt wurden, sind die Urheberrechte Dritter zu beachten.
					</p>
					<p>
						Sollten Sie auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir Sie um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
						betreffende Inhalte umgehend entfernen. Wir weisen darauf hin, dass eine Verletzung unserer Urheber- oder sonstigen Schutzrechte zivil- und/oder strafrechtliche
						Konsequenzen nach sich ziehen kann.
					</p>

					<h3 className="h3 text-white pt-12 pb-6">Abruf der rechtlichen Informationen</h3>
					<p>
						Sie können unsere Hinweise zu den rechtlichen Informationen jederzeit auf dieser Internetseite abrufen. Eventuelle Änderungen an unseren rechtlichen Informationen
						veröffentlichen wir ebenfalls hier. Die permanente Internetadresse dieser Seite lautet<span>&nbsp;</span>
						<a href="https://www.purmacherei.de/rechtliches/">https://www.purmacherei.de/rechtliches/</a>.
					</p>
				</div>
			</div>
			<div className="text-center pt-5 py-10">
				<button onClick={() => navigate(-1)} className="btn  btn-invert mt-8">
					Zurück
				</button>
			</div>
		</div>
	)
}
export default Imprint
